import { CSSProperties, useState, useEffect } from 'react';
import Graph from '../Graph';
import { JSON_DATA, JSON_TEXT } from '../../constants';
import { GraphProps } from '../../data/types';
import { useRegion } from '../../contexts/RegionContext';
import { useSize } from '../../contexts/SizeContext';
import { useIndustry } from '../../contexts/IndustryContext';
import { useLang } from '../../contexts/LangContext';
import { getTypeface } from '../../utils/style';

type VisualizationProps = {
  id?: string;
  width?: number;
  height?: number;
  onDisclaimerToggle: Function;
  onDisclaimerSizeToggle: Function;
};

const Visualization = ({
  id,
  width,
  height,
  onDisclaimerToggle,
  onDisclaimerSizeToggle
}: VisualizationProps) => {
  // console.log('[Visualization] ======================', id);
  const sizeContext = useSize();
  const sizeDefault = 'size_all';

  const regionContext = useRegion();
  const regionDefault = 'region_all';

  const industryContext = useIndustry();
  const industryDefault = 'industry_all';

  const langContext = useLang();
  const langDefault = 'en-us'; // Default language used for chart labels

  const [content, setContent] = useState<any>([{}]);
  const style: CSSProperties = {
    width: width,
    height: height
  };

  useEffect(() => {
    // Returns the dataset content
    const getDataset = ({
      id,
      pageId,
      sizeId,
      regionId,
      industryId
    }: {
      id: string;
      pageId?: string;
      sizeId?: string;
      regionId?: string;
      industryId?: string;
    }) => {
      // console.log(
      //   '[Visualization] getDataset',
      //   id,
      //   pageId,
      //   sizeId,
      //   regionId,
      //   industryId
      // );
      let dataset = '';
      let regionOverrideId = '';
      let sizeOverrideId = '';

      // Determine special case for region override
      if (
        pageId === 'data-1' ||
        pageId === 'data-2' ||
        pageId === 'data-3' ||
        pageId === 'data-4' ||
        pageId === 'data-5' ||
        pageId === 'data-6'
      ) {
        switch (regionId) {
          case 'region_india':
          case 'region_japan':
            onDisclaimerToggle(true);
            regionOverrideId = 'region_apac';
            break;
          default:
            onDisclaimerToggle(false);
            regionOverrideId = '';
        }

        if (pageId === 'data-5') {
          switch (sizeId) {
            case 'size_smb':
            case 'size_enterprise':
              sizeOverrideId = sizeDefault;
              onDisclaimerSizeToggle(true);
              break;
            default:
              onDisclaimerSizeToggle(false);
              sizeOverrideId = '';
          }
        }
      }

      // If size and region are valid...
      if (sizeId && regionId) {
        if (regionOverrideId) {
          regionId = regionOverrideId;
        }
        if (sizeOverrideId) {
          sizeId = sizeOverrideId;
        }
        // For all datasets with industryId
        if (industryId) {
          dataset = JSON_DATA.dataset[id][sizeId][regionId][industryId];
        } else {
          // For all other datasets that is not gated with industry
          dataset = JSON_DATA.dataset[id][sizeId][regionId];
        }
      }
      return dataset;
    };

    // Established the correct JSON_TEXT for language pull
    const chartLabels =
      JSON_TEXT[langContext ? langContext.id : langDefault].chartLabels;

    const TYPEFACE = getTypeface(langContext ? langContext.id : langDefault);

    if (id !== undefined) {
      switch (id) {
        case 'realities-1':
          setContent([
            {
              id: 'infrastructure-workload-location',
              type: 'barRealities',
              data: getDataset({
                id: 'I2I6',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryContext?.id
              }),
              padding: {
                top: 0,
                bottom: 0,
                left: 30,
                right: 30
              },
              style: {
                width: 800,
                height: 500,
                top: 170,
                left: 360
              },
              // domain: { y: [0, 0] },
              domainPadding: { x: [20, 150], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.infrastructureLocations,
                  // text: 'Infrastructure locations',
                  x: 30,
                  y: 220,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.header }
                },
                {
                  text: chartLabels.workloadLocations,
                  // text: 'Workload locations',
                  x: 30,
                  y: 450,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.header }
                }
              ],
              labelSettings: {
                backgroundPadding: [
                  0,
                  0,
                  { top: 40, bottom: 0, left: 0, right: 0 }
                ],
                dy: -30,
                // dx: -50,
                textAnchor: 'middle',
                verticalAnchor: 'end'
              },
              additional: {
                showOnly: ['current-workload', 'current-infrastructure'],
                barWidth: 100,
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted green'
                // }
              }
            }
          ]);
          break;
        case 'realities-2':
          setContent([
            {
              id: 'infrastructure-workload-location',
              type: 'barRealities',
              data: getDataset({
                id: 'I2I6',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryContext?.id
              }),
              padding: {
                top: 0,
                bottom: 0,
                left: 30,
                right: 30
              },
              style: {
                width: 400,
                height: 500,
                top: 170,
                left: 4
              },
              // domain: { y: [0, 100] },
              domainPadding: { x: [20, 150], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.infrastructureLocations,
                  // text: 'Infrastructure locations',
                  x: 30,
                  y: 220,
                  textAnchor: 'start'
                },
                {
                  text: chartLabels.workloadLocations,
                  // text: 'Workload locations',
                  x: 30,
                  y: 450,
                  textAnchor: 'start'
                }
              ],
              labelSettings: {
                backgroundPadding: [
                  0,
                  0,
                  { top: 40, bottom: 0, left: 0, right: 0 }
                ],
                dy: -30,
                // dx: -100,
                textAnchor: 'middle',
                verticalAnchor: 'end'
              },
              additional: {
                showOnly: ['current-workload', 'current-infrastructure'],
                isAlt: true,
                barWidth: 100,
                // labelDy: 9,
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted green'
                // }
              }
            },
            {
              id: 'private-bg',
              type: 'barStandard',
              data: [
                {
                  x: 'private-reasons',
                  y: 100,
                  color: '#7630ea',
                  altColor: '#d8d8d8'
                }
              ],
              padding: {
                top: 0,
                bottom: 30,
                left: 0,
                right: 0
              },
              style: {
                width: 380,
                height: 675,
                top: 0,
                left: 440
              },
              domain: { y: [0, 100] },
              domainPadding: { x: [230, 230], y: [0, 80] },
              labelSettings: {
                backgroundPadding: 0,
                dy: -10,
                dx: 0,
                textAnchor: 'start',
                verticalAnchor: 'end'
              },
              additional: {
                barWidth: 380,
                cornerRadius: { top: 10, bottom: 10 },
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted green'
                // }
              }
            },
            {
              id: 'public-label',
              type: 'label',
              padding: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
              },
              style: {
                width: 650,
                height: 30,
                top: 640,
                left: 460
              },
              labelContent: [
                {
                  text: chartLabels.cloudExperienceExpectationsOnPrem,
                  x: 0,
                  y: 20,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.header }
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                dy: 0,
                textAnchor: 'start',
                verticalAnchor: 'end'
              },
              additional: {
                // style: {
                //   border: '1px dotted green'
                // }
              }
            },
            {
              id: 'private-list',
              type: 'contentList',
              data: getDataset({
                id: 'C1',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryContext?.id
              }),
              padding: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
              },
              style: {
                width: 340,
                height: 570,
                top: 100,
                left: 460
              },
              domain: { y: [0, 20] },
              domainPadding: { x: [100, 100], y: [0, 0] },
              labelSettings: {
                backgroundPadding: 0,
                dy: -10,
                dx: 0,
                textAnchor: 'start',
                verticalAnchor: 'end',
                style: {
                  ...TYPEFACE.bodyL,
                  textTransform: 'none',
                  left: 0,
                  fill: '#ffffff'
                }
              },
              additional: {
                sortKey: 'responseCount',
                barWidth: 10,
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted red'
                // }
              }
            }
          ]);
          break;
        case 'realities-3':
          setContent([
            {
              id: 'infrastructure-workload-location',
              type: 'barRealities',
              data: getDataset({
                id: 'I2I6',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryContext?.id
              }),
              padding: {
                top: 0,
                bottom: 0,
                left: 30,
                right: 30
              },
              style: {
                width: 400,
                height: 500,
                top: 170,
                left: 4
              },
              // domain: { y: [0, 100] },
              domainPadding: { x: [20, 150], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.infrastructureLocations,
                  // text: 'Infrastructure locations',
                  x: 30,
                  y: 220,
                  textAnchor: 'start'
                },
                {
                  text: chartLabels.workloadLocations,
                  // text: 'Workload locations',
                  x: 30,
                  y: 450,
                  textAnchor: 'start'
                }
              ],
              labelSettings: {
                backgroundPadding: [
                  0,
                  0,
                  { top: 40, bottom: 0, left: 0, right: 0 }
                ],
                dy: -30,
                // dx: -100,
                textAnchor: 'middle',
                verticalAnchor: 'end'
              },
              additional: {
                showOnly: ['current-workload', 'current-infrastructure'],
                isAlt: true,
                barWidth: 100,
                // labelDy: 9,
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted green'
                // }
              }
            },
            {
              id: 'covid-list',
              type: 'barEmbeddedDetailList',
              data: getDataset({
                id: 'D1',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryContext?.id
              }),
              padding: {
                top: 0,
                bottom: 0,
                left: 10,
                right: 0
              },
              style: {
                width: 700,
                height: 600,
                top: 70,
                left: 450
              },
              domain: { y: [0, 30] },
              domainPadding: { x: [80, 80], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.criticalDriversOfTStrategyDueToCOVID19,
                  // text: 'Critical drivers of IT strategy due to COVID-19',
                  x: 10,
                  y: 590,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.header }
                }
              ],
              labelSettings: {
                backgroundPadding: [0, 0, { top: 8 }],
                dy: -60,
                dyOffset: -52,
                dxOffset: 20,
                textAnchor: 'start',
                verticalAnchor: 'start'
              },
              additional: {
                // sortKey: 'y',
                barWidth: 40,
                cornerRadius: { top: 0, bottom: 0 },
                textAnchor: 'start',
                verticalAnchor: 'end',
                style: {
                  borderLeft: '1px solid #000'
                }
              }
            }
          ]);
          break;
        case 'realities-4':
          setContent([
            {
              id: 'current',
              type: 'barRealities',
              data: getDataset({
                id: 'I2I6',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryContext?.id
              }),
              padding: {
                top: 0,
                bottom: 0,
                left: 30,
                right: 30
              },
              style: {
                width: 800,
                height: 650,
                top: 20,
                left: 360
              },
              domain: { y: [0, 100] },
              domainPadding: { x: [100, 100], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.currentInfrastructureLocations,
                  //text: 'Current infrastructure locations',
                  x: 30,
                  y: 135,
                  textAnchor: 'start',
                  style: {
                    ...TYPEFACE.header,
                    fill: '#cccccc'
                  }
                },
                {
                  text: chartLabels.currentWorkloadLocations,
                  // text: 'Current workload locations',
                  x: 30,
                  y: 445,
                  textAnchor: 'start',
                  style: {
                    ...TYPEFACE.header,
                    fill: '#cccccc'
                  }
                }
              ],
              labelSettings: {},
              additional: {
                showOnly: ['current-workload', 'current-infrastructure'],
                barWidth: 50,
                custom: [
                  {
                    id: 'current-workload',
                    barWidth: 40,
                    isAltColor: true,
                    labelSettings: {
                      backgroundPadding: [
                        0,
                        0,
                        { top: 20, bottom: 0, left: 0, right: 0 }
                      ],
                      style: [
                        {
                          ...TYPEFACE.body,
                          fill: '#cccccc'
                        },
                        {
                          ...TYPEFACE.body,
                          fill: '#cccccc'
                        },
                        {
                          ...TYPEFACE.headerL,
                          fill: '#ffffff'
                        }
                      ],
                      dy: -10,
                      textAnchor: 'middle',
                      verticalAnchor: 'end'
                    }
                  },
                  {
                    id: 'current-infrastructure',
                    barWidth: 40,
                    isAltColor: true,
                    labelSettings: {
                      backgroundPadding: [
                        0,
                        0,
                        { top: 20, bottom: 0, left: 0, right: 0 }
                      ],
                      style: [
                        {
                          ...TYPEFACE.body,
                          fill: '#cccccc'
                        },
                        {
                          ...TYPEFACE.body,
                          fill: '#cccccc'
                        },
                        {
                          ...TYPEFACE.headerL,
                          fill: '#ffffff'
                        }
                      ],
                      dy: -10,
                      textAnchor: 'middle',
                      verticalAnchor: 'end'
                    }
                  }
                ],
                labelDy: 9,
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted green'
                // }
              }
            },
            {
              id: 'future',
              type: 'barRealities',
              data: getDataset({
                id: 'I2I6',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryContext?.id
              }),
              padding: {
                top: 0,
                bottom: 0,
                left: 30,
                right: 30
              },
              style: {
                width: 800,
                height: 650,
                top: 20,
                left: 360
              },
              domain: { y: [0, 100] },
              domainPadding: { x: [100, 100], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.futureInfrastructureLocations,
                  // text: 'Future infrastructure locations',
                  x: 30,
                  y: 315,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.header }
                },
                {
                  text: chartLabels.futureWorkloadLocations,
                  // text: 'Future workload locations',
                  x: 30,
                  y: 625,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.header }
                }
              ],
              labelSettings: {},
              additional: {
                showOnly: ['future-workload', 'future-infrastructure'],
                barWidth: 50,
                custom: [
                  {
                    id: 'future-workload',
                    barWidth: 100,
                    isAltColor: false,
                    labelSettings: {
                      backgroundPadding: [
                        0,
                        0,
                        { top: 35, bottom: 0, left: 0, right: 0 }
                      ],
                      dy: -20,
                      textAnchor: 'middle',
                      verticalAnchor: 'end'
                    },
                    labelStyle: [
                      {
                        ...TYPEFACE.body,
                        fill: '#000000'
                      },
                      {
                        ...TYPEFACE.body,
                        fill: '#000000'
                      },
                      {
                        ...TYPEFACE.headerXXL,
                        fill: '#ffffff'
                      }
                    ]
                  },
                  {
                    id: 'future-infrastructure',
                    barWidth: 100,
                    isAltColor: false,
                    labelSettings: {
                      backgroundPadding: [
                        0,
                        0,
                        { top: 40, bottom: 0, left: 0, right: 0 }
                      ],
                      dy: -30,
                      textAnchor: 'middle',
                      verticalAnchor: 'end'
                    },
                    labelStyle: [
                      {
                        ...TYPEFACE.body,
                        fill: '#000000'
                      },
                      {
                        ...TYPEFACE.body,
                        fill: '#000000'
                      },
                      {
                        ...TYPEFACE.headerXL,
                        fill: '#ffffff'
                      }
                    ]
                  }
                ],
                labelDy: 9,
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted green'
                // }
              }
            }
          ]);
          break;
        case 'realities-5':
          setContent([
            {
              id: 'current',
              type: 'barRealities',
              data: getDataset({
                id: 'I2I6',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryContext?.id
              }),
              padding: {
                top: 0,
                bottom: 0,
                left: 30,
                right: 30
              },
              style: {
                width: 800,
                height: 650,
                top: 20,
                left: 360
              },
              domain: { y: [0, 100] },
              domainPadding: { x: [100, 100], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.currentInfrastructureLocations,
                  //text: 'Current infrastructure locations',
                  x: 30,
                  y: 135,
                  textAnchor: 'start',
                  style: {
                    ...TYPEFACE.header,
                    fill: '#cccccc'
                  }
                },
                {
                  text: chartLabels.currentWorkloadLocations,
                  // text: 'Current workload locations',
                  x: 30,
                  y: 445,
                  textAnchor: 'start',
                  style: {
                    ...TYPEFACE.header,
                    fill: '#cccccc'
                  }
                }
              ],
              labelSettings: {},
              additional: {
                showOnly: ['current-workload', 'current-infrastructure'],
                barWidth: 50,
                custom: [
                  {
                    id: 'current-workload',
                    barWidth: 40,
                    isAltColor: true,
                    labelSettings: {
                      backgroundPadding: [
                        0,
                        0,
                        { top: 20, bottom: 0, left: 0, right: 0 }
                      ],
                      style: [
                        {
                          ...TYPEFACE.body,
                          fill: '#cccccc'
                        },
                        {
                          ...TYPEFACE.body,
                          fill: '#cccccc'
                        },
                        {
                          ...TYPEFACE.headerL,
                          fill: '#ffffff'
                        }
                      ],
                      dy: -10,
                      textAnchor: 'middle',
                      verticalAnchor: 'end'
                    }
                  },
                  {
                    id: 'current-infrastructure',
                    barWidth: 40,
                    isAltColor: true,
                    labelSettings: {
                      backgroundPadding: [
                        0,
                        0,
                        { top: 20, bottom: 0, left: 0, right: 0 }
                      ],
                      style: [
                        {
                          ...TYPEFACE.body,
                          fill: '#cccccc'
                        },
                        {
                          ...TYPEFACE.body,
                          fill: '#cccccc'
                        },
                        {
                          ...TYPEFACE.headerL,
                          fill: '#ffffff'
                        }
                      ],
                      dy: -10,
                      textAnchor: 'middle',
                      verticalAnchor: 'end'
                    }
                  }
                ],
                labelDy: 9,
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted green'
                // }
              }
            },
            {
              id: 'future',
              type: 'barRealities',
              data: getDataset({
                id: 'I2I6',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryContext?.id
              }),
              padding: {
                top: 0,
                bottom: 0,
                left: 30,
                right: 30
              },
              style: {
                width: 800,
                height: 650,
                top: 20,
                left: 360
              },
              domain: { y: [0, 100] },
              domainPadding: { x: [100, 100], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.futureInfrastructureLocations,
                  // text: 'Future infrastructure locations',
                  x: 30,
                  y: 315,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.header }
                },
                {
                  text: chartLabels.futureWorkloadLocations,
                  // text: 'Future workload locations',
                  x: 30,
                  y: 625,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.header }
                }
              ],
              labelSettings: {},
              additional: {
                showOnly: ['future-workload', 'future-infrastructure'],
                barWidth: 50,
                custom: [
                  {
                    id: 'future-workload',
                    barWidth: 100,
                    isAltColor: false,
                    labelSettings: {
                      backgroundPadding: [
                        0,
                        0,
                        { top: 35, bottom: 0, left: 0, right: 0 }
                      ],
                      dy: -20,
                      textAnchor: 'middle',
                      verticalAnchor: 'end'
                    },
                    labelStyle: [
                      {
                        ...TYPEFACE.body,
                        fill: '#000000'
                      },
                      {
                        ...TYPEFACE.body,
                        fill: '#000000'
                      },
                      {
                        ...TYPEFACE.headerXXL,
                        fill: '#ffffff'
                      }
                    ]
                  },
                  {
                    id: 'future-infrastructure',
                    barWidth: 100,
                    isAltColor: false,
                    labelSettings: {
                      backgroundPadding: [
                        0,
                        0,
                        { top: 40, bottom: 0, left: 0, right: 0 }
                      ],
                      dy: -30,
                      textAnchor: 'middle',
                      verticalAnchor: 'end'
                    },
                    labelStyle: [
                      {
                        ...TYPEFACE.body,
                        fill: '#000000'
                      },
                      {
                        ...TYPEFACE.body,
                        fill: '#000000'
                      },
                      {
                        ...TYPEFACE.headerXL,
                        fill: '#ffffff'
                      }
                    ]
                  }
                ],
                labelDy: 9,
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted green'
                // }
              }
            }
          ]);
          break;
        case 'realities-6':
          setContent([
            {
              id: 'current',
              type: 'barRealities',
              data: getDataset({
                id: 'I2I6',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryDefault
              }),
              padding: {
                top: 0,
                bottom: 0,
                left: 30,
                right: 30
              },
              style: {
                width: 800,
                height: 650,
                top: 20,
                left: 620
              },
              domain: { y: [0, 100] },
              domainPadding: { x: [100, 100], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.currentInfrastructureLocations,
                  //text: 'Current infrastructure locations',
                  x: 30,
                  y: 135,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.header }
                },
                {
                  text: chartLabels.currentWorkloadLocations,
                  // text: 'Current workload locations',
                  x: 30,
                  y: 445,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.header }
                }
              ],
              labelSettings: {},
              additional: {
                showOnly: ['current-workload', 'current-infrastructure'],
                barWidth: 50,
                custom: [
                  {
                    id: 'current-workload',
                    barWidth: 40,
                    isAltColor: false,
                    labelSettings: {
                      backgroundPadding: [
                        0,
                        0,
                        { top: 20, bottom: 0, left: 0, right: 0 }
                      ],
                      style: [
                        {
                          ...TYPEFACE.body,
                          fill: '#000000'
                        },
                        {
                          ...TYPEFACE.body,
                          fill: '#000000'
                        },
                        {
                          ...TYPEFACE.headerL,
                          fill: '#ffffff'
                        }
                      ],
                      dy: -10,
                      textAnchor: 'middle',
                      verticalAnchor: 'end'
                    }
                  },
                  {
                    id: 'current-infrastructure',
                    barWidth: 40,
                    isAltColor: false,
                    labelSettings: {
                      backgroundPadding: [
                        0,
                        0,
                        { top: 20, bottom: 0, left: 0, right: 0 }
                      ],
                      style: [
                        {
                          ...TYPEFACE.body,
                          fill: '#000000'
                        },
                        {
                          ...TYPEFACE.body,
                          fill: '#000000'
                        },
                        {
                          ...TYPEFACE.headerL,
                          fill: '#ffffff'
                        }
                      ],
                      dy: -10,
                      textAnchor: 'middle',
                      verticalAnchor: 'end'
                    }
                  }
                ],
                labelDy: 9,
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted green'
                // }
              }
            },
            {
              id: 'future',
              type: 'barRealities',
              data: getDataset({
                id: 'I2I6',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryDefault
              }),
              padding: {
                top: 0,
                bottom: 0,
                left: 30,
                right: 30
              },
              style: {
                width: 800,
                height: 650,
                top: 20,
                left: 620
              },
              domain: { y: [0, 100] },
              domainPadding: { x: [100, 100], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.futureInfrastructureLocations,
                  // text: 'Future infrastructure locations',
                  x: 30,
                  y: 315,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.header }
                },
                {
                  text: chartLabels.futureWorkloadLocations,
                  // text: 'Future workload locations',
                  x: 30,
                  y: 625,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.header }
                }
              ],
              labelSettings: {},
              additional: {
                showOnly: ['future-workload', 'future-infrastructure'],
                barWidth: 50,
                custom: [
                  {
                    id: 'future-workload',
                    barWidth: 100,
                    isAltColor: false,
                    labelSettings: {
                      backgroundPadding: [
                        0,
                        0,
                        { top: 35, bottom: 0, left: 0, right: 0 }
                      ],
                      dy: -20,
                      textAnchor: 'middle',
                      verticalAnchor: 'end'
                    },
                    labelStyle: [
                      {
                        ...TYPEFACE.body,
                        fill: '#000000'
                      },
                      {
                        ...TYPEFACE.body,
                        fill: '#000000'
                      },
                      {
                        ...TYPEFACE.headerXXL,
                        fill: '#ffffff'
                      }
                    ]
                  },
                  {
                    id: 'future-infrastructure',
                    barWidth: 100,
                    isAltColor: false,
                    labelSettings: {
                      backgroundPadding: [
                        0,
                        0,
                        { top: 40, bottom: 0, left: 0, right: 0 }
                      ],
                      dy: -30,
                      textAnchor: 'middle',
                      verticalAnchor: 'end'
                    },
                    labelStyle: [
                      {
                        ...TYPEFACE.body,
                        fill: '#000000'
                      },
                      {
                        ...TYPEFACE.body,
                        fill: '#000000'
                      },
                      {
                        ...TYPEFACE.headerXL,
                        fill: '#ffffff'
                      }
                    ]
                  }
                ],
                labelDy: 9,
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted green'
                // }
              }
            }
          ]);
          break;
        case 'workload-1':
          setContent([
            {
              id: 'workload-list',
              type: 'barEmbeddedDetailList',
              data: getDataset({
                id: 'I5',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryContext?.id
              }),
              padding: {
                top: 20,
                bottom: 10,
                left: 0,
                right: 0
              },
              style: {
                width: 1100,
                height: 520,
                top: 150,
                left: 30
              },
              domain: { y: [0, 15] },
              domainPadding: { x: [50, 50], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.topSixSignificantWorkloadInvestments,
                  // text: 'Top six significant workload investments',
                  x: 0,
                  y: 510,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.header }
                }
              ],
              labelSettings: {
                backgroundPadding: [0, 0, { top: 8 }],
                dy: -10,
                dyOffset: 0,
                dx: 0,
                dxOffset: 10,
                textAnchor: 'start',
                verticalAnchor: 'end'
              },
              additional: {
                // sortKey: 'y',
                barWidth: 40,
                cornerRadius: { top: 0, bottom: 0 },
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted red'
                // }
              }
            }
          ]);
          break;
        case 'workload-2':
          setContent([
            {
              id: 'workload-location',
              type: 'locationStack',
              data: getDataset({
                id: 'I6',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryContext?.id
              }),
              padding: {
                top: 20,
                bottom: 0,
                left: 225,
                right: 0
              },
              style: {
                width: 1125,
                height: 460,
                top: 0,
                left: 30
              },
              // domain: { y: [0, 100] },
              domainPadding: { x: [50, 50], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.allPublicCloud,
                  // text: '100%\nPublic Cloud',
                  x: 270,
                  y: 20,
                  textAnchor: 'middle',
                  verticalAnchor: 'end'
                },
                {
                  text: chartLabels.mostlyPublicCloud,
                  // text: 'Mostly\nPublic Cloud',
                  x: 420,
                  y: 20,
                  textAnchor: 'middle',
                  verticalAnchor: 'end'
                },
                {
                  text: chartLabels.equalMix,
                  // text: 'Equal\nMix',
                  x: 630,
                  y: 20,
                  textAnchor: 'middle',
                  verticalAnchor: 'end'
                },
                {
                  text: chartLabels.mostlyOnPrem,
                  // text: 'Mostly\nOn-Prem',
                  x: 880,
                  y: 20,
                  textAnchor: 'middle',
                  verticalAnchor: 'end'
                },
                {
                  text: chartLabels.allOnPrem,
                  // text: '100%\nOn-Prem',
                  x: 1060,
                  y: 20,
                  textAnchor: 'middle',
                  verticalAnchor: 'end'
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                dy: 10,
                // dx: 10,
                textAnchor: 'middle',
                verticalAnchor: 'end'
              },
              additional: {
                hideLabel: false,
                barWidth: 50,
                // labelDy: 9,
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted green'
                // }
              }
            }
          ]);
          break;
        case 'workload-3':
          setContent([
            {
              id: 'current-workload-location',
              type: 'locationStack',
              data: getDataset({
                id: 'I6',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryContext?.id
              }),
              padding: {
                top: 0,
                bottom: 20,
                left: 225,
                right: 0
              },
              style: {
                width: 1125,
                height: 460,
                top: 0,
                left: 30
              },
              // domain: { y: [0, 100] },
              domainPadding: { x: [50, 50], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.allPublicCloud,
                  // text: '100%\nPublic Cloud',
                  x: 270,
                  y: 20,
                  textAnchor: 'middle',
                  verticalAnchor: 'end'
                },
                {
                  text: chartLabels.mostlyPublicCloud,
                  // text: 'Mostly\nPublic Cloud',
                  x: 420,
                  y: 20,
                  textAnchor: 'middle',
                  verticalAnchor: 'end'
                },
                {
                  text: chartLabels.equalMix,
                  // text: 'Equal\nMix',
                  x: 630,
                  y: 20,
                  textAnchor: 'middle',
                  verticalAnchor: 'end'
                },
                {
                  text: chartLabels.mostlyOnPrem,
                  // text: 'Mostly\nOn-Prem',
                  x: 880,
                  y: 20,
                  textAnchor: 'middle',
                  verticalAnchor: 'end'
                },
                {
                  text: chartLabels.allOnPrem,
                  // text: '100%\nOn-Prem',
                  x: 1060,
                  y: 20,
                  textAnchor: 'middle',
                  verticalAnchor: 'end'
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                dy: 10,
                textAnchor: 'middle',
                verticalAnchor: 'end'
              },
              additional: {
                // isAltColor: true,
                hideLabel: true,
                hideTitle: true,
                barWidth: 10,
                // labelDy: 0,
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted green'
                // }
              }
            },
            {
              id: 'future-workload-location',
              type: 'locationStack',
              data: getDataset({
                id: 'I7',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryContext?.id
              }),
              padding: {
                top: 0,
                bottom: 20,
                left: 225,
                right: 0
              },
              style: {
                width: 1125,
                height: 460,
                top: 0,
                left: 30
              },
              // domain: { y: [0, 100] },
              domainPadding: { x: [25, 75], y: [0, 0] },
              labelContent: [],
              labelSettings: {
                backgroundPadding: 0,
                dy: 10,
                textAnchor: 'middle',
                verticalAnchor: 'end'
              },
              additional: {
                hideLabel: false,
                hideTitle: false,
                barWidth: 35,
                // labelDy: 9,
                textAnchor: 'start',
                verticalAnchor: 'end'
              }
            }
          ]);
          break;
        case 'workload-4':
          setContent([
            {
              id: 'current-workload-location',
              type: 'locationStack',
              data: getDataset({
                id: 'I6',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryContext?.id
              }),
              padding: {
                top: 0,
                bottom: 20,
                left: 225,
                right: 0
              },
              style: {
                width: 1125,
                height: 460,
                top: 0,
                left: 30
              },
              // domain: { y: [0, 100] },
              domainPadding: { x: [50, 50], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.allPublicCloud,
                  // text: '100%\nPublic Cloud',
                  x: 270,
                  y: 20,
                  textAnchor: 'middle',
                  verticalAnchor: 'end'
                },
                {
                  text: chartLabels.mostlyPublicCloud,
                  // text: 'Mostly\nPublic Cloud',
                  x: 420,
                  y: 20,
                  textAnchor: 'middle',
                  verticalAnchor: 'end'
                },
                {
                  text: chartLabels.equalMix,
                  // text: 'Equal\nMix',
                  x: 630,
                  y: 20,
                  textAnchor: 'middle',
                  verticalAnchor: 'end'
                },
                {
                  text: chartLabels.mostlyOnPrem,
                  // text: 'Mostly\nOn-Prem',
                  x: 880,
                  y: 20,
                  textAnchor: 'middle',
                  verticalAnchor: 'end'
                },
                {
                  text: chartLabels.allOnPrem,
                  // text: '100%\nOn-Prem',
                  x: 1060,
                  y: 20,
                  textAnchor: 'middle',
                  verticalAnchor: 'end'
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                dy: 10,
                textAnchor: 'middle',
                verticalAnchor: 'end'
              },
              additional: {
                hideLabel: true,
                hideTitle: true,
                isAltColor: true,
                barWidth: 10,
                // labelDy: 0,
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted green'
                // }
              }
            },
            {
              id: 'future-workload-location',
              type: 'locationStack',
              data: getDataset({
                id: 'I7',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryContext?.id
              }),
              padding: {
                top: 0,
                bottom: 20,
                left: 225,
                right: 0
              },
              style: {
                width: 1125,
                height: 460,
                top: 0,
                left: 30
              },
              // domain: { y: [0, 100] },
              domainPadding: { x: [25, 75], y: [0, 0] },
              labelContent: [],
              labelSettings: {
                backgroundPadding: 0,
                dy: 10,
                textAnchor: 'middle',
                verticalAnchor: 'end'
              },
              additional: {
                hideLabel: false,
                hideTitle: false,
                barWidth: 35,
                // labelDy: 9,
                textAnchor: 'start',
                verticalAnchor: 'end'
              }
            }
          ]);
          break;
        case 'workload-5':
          setContent([
            {
              id: 'future-workload-location',
              type: 'locationStack',
              data: getDataset({
                id: 'I7',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryDefault
              }),
              padding: {
                top: 0,
                bottom: 20,
                left: 250,
                right: 0
              },
              style: {
                width: 700,
                height: 460,
                top: 0,
                left: 685
              },
              // domain: { y: [0, 100] },
              domainPadding: { x: [25, 75], y: [0, 0] },
              labelContent: [],
              labelSettings: {
                backgroundPadding: 0,
                dy: 10,
                textAnchor: 'middle',
                verticalAnchor: 'end'
              },
              additional: {
                hideLabel: false,
                dxModifier: 0.0032,
                hideTitle: false,
                barWidth: 35,
                // labelDy: 9,
                textAnchor: 'start',
                verticalAnchor: 'end'
              }
            },
            {
              id: 'current-workload-location',
              type: 'locationStack',
              data: getDataset({
                id: 'I6',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryDefault
              }),
              padding: {
                top: 0,
                bottom: 20,
                left: 250,
                right: 0
              },
              style: {
                width: 700,
                height: 460,
                top: 0,
                left: 685
              },
              // domain: { y: [0, 100] },
              domainPadding: { x: [50, 50], y: [0, 0] },
              labelContent: [],
              labelSettings: {
                backgroundPadding: 0,
                dy: 10,
                textAnchor: 'middle',
                verticalAnchor: 'end'
              },
              additional: {
                hideLabel: true,
                hideTitle: true,
                // isAltColor: true,
                barWidth: 10,
                // labelDy: 0,
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted green'
                // }
              }
            }
          ]);
          break;
        case 'data-1':
          setContent([
            {
              id: 'data-location',
              type: 'barStandard',
              data: getDataset({
                id: 'DA1',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id
              }),
              padding: {
                top: 0,
                bottom: 30,
                left: 50,
                right: 150
              },
              style: {
                width: 740,
                height: 675,
                top: 0,
                left: 425
              },
              domain: { y: [0, 100] },
              domainPadding: { x: [100, 120], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.dataLocation,
                  // text: 'Data Location',
                  x: 60,
                  y: 660,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.header }
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                dy: 10,
                textAnchor: 'start',
                verticalAnchor: 'start'
              },
              additional: {
                isWeighted: true,
                barWidth: 650,
                cornerRadius: { top: 10, bottom: 10 },
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted red'
                // }
              }
            }
          ]);
          break;
        case 'data-2':
          setContent([
            {
              id: 'data-location',
              type: 'barStandard',
              data: getDataset({
                id: 'DA1',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id
              }),
              padding: {
                top: 0,
                bottom: 30,
                left: 0,
                right: 0
              },
              style: {
                width: 380,
                height: 675,
                top: 0,
                left: 25
              },
              domain: { y: [0, 100] },
              domainPadding: { x: [120, 120], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.dataLocation,
                  // text: 'Data Location',
                  x: 0,
                  y: 660,
                  textAnchor: 'start'
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                dy: 10,
                dx: -40,
                textAnchor: 'start',
                verticalAnchor: 'start'
              },
              additional: {
                barWidth: 180,
                cornerRadius: { top: 10, bottom: 10 },
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted green'
                // }
              }
            },
            {
              id: 'maturity',
              type: 'barHorizontal',
              data: getDataset({
                id: 'D20',
                // pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id
              }),
              padding: {
                top: 0,
                bottom: 0,
                left: 10,
                right: 0
              },
              style: {
                width: 800,
                height: 600,
                top: 70,
                left: 430
              },
              domain: { x: [0, 5], y: [0, 100] },
              domainPadding: { x: [0, 0], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.dataStrategyMaturity,
                  // text: 'Data strategy maturity',
                  x: 10,
                  y: 590,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.header }
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                dy: 10,
                textAnchor: 'start',
                verticalAnchor: 'end'
              },
              additional: {
                barWidth: 30,
                labelDy: 9,
                textAnchor: 'start',
                verticalAnchor: 'end',
                style: {
                  borderLeft: '1px solid #000'
                }
              }
            }
          ]);
          break;
        case 'data-3':
          setContent([
            {
              id: 'data-location',
              type: 'barStandard',
              data: getDataset({
                id: 'DA1',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id
              }),
              padding: {
                top: 0,
                bottom: 30,
                left: 0,
                right: 0
              },
              style: {
                width: 380,
                height: 675,
                top: 0,
                left: 25
              },
              domain: { y: [0, 100] },
              domainPadding: { x: [120, 120], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.dataLocation,
                  // text: 'Data Location',
                  x: 0,
                  y: 660,
                  textAnchor: 'start'
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                dy: 10,
                dx: -40,
                textAnchor: 'start',
                verticalAnchor: 'start'
              },
              additional: {
                barWidth: 180,
                cornerRadius: { top: 10, bottom: 10 },
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted green'
                // }
              }
            },
            {
              id: 'egress',
              type: 'barVertical',
              data: getDataset({
                id: 'D7',
                // pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id
              }),
              padding: {
                top: 0,
                bottom: 25,
                left: 0,
                right: 0
              },
              style: {
                width: 800,
                height: 600,
                top: 70,
                left: 430
              },
              domain: { y: [0, 90] },
              domainPadding: { x: [80, 140], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.dataEgressCostConcern,
                  // text: 'Data egress cost concern',
                  x: 10,
                  y: 590,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.header }
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                dy: -20,
                dx: -30,
                textAnchor: 'start',
                verticalAnchor: 'end'
              },
              additional: {
                barWidth: 70,
                labelDy: 0,
                textAnchor: 'start',
                verticalAnchor: 'end',
                style: {
                  borderLeft: '1px solid #000'
                }
              }
            }
          ]);
          break;
        case 'data-4':
          setContent([
            {
              id: 'data-location',
              type: 'barStandard',
              data: getDataset({
                id: 'DA1',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id
              }),
              padding: {
                top: 0,
                bottom: 30,
                left: 0,
                right: 145
              },
              style: {
                width: 635,
                height: 675,
                top: 0,
                left: 390
              },
              domain: { y: [0, 100] },
              domainPadding: { x: [100, 100], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.dataLocation,
                  // text: 'Data Location',
                  x: 315,
                  y: 660,
                  textAnchor: 'middle'
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                dy: 10,
                textAnchor: 'start',
                verticalAnchor: 'start'
              },
              additional: {
                isAlt: true,
                barWidth: 600,
                cornerRadius: { top: 10, bottom: 10 },
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted red'
                // }
              }
            },
            {
              id: 'public-label',
              type: 'label',
              padding: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
              },
              style: {
                width: 380,
                height: 50,
                top: 640,
                left: 0
              },
              labelContent: [
                {
                  text: chartLabels.percentageOfRespondents,
                  // text: ['% of respondents'],
                  x: 378,
                  y: 20,
                  textAnchor: 'end',
                  style: { ...TYPEFACE.header }
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                dy: 0,
                textAnchor: 'end',
                verticalAnchor: 'end'
              },
              additional: {
                // style: {
                //   border: '1px dotted green'
                // }
              }
            },
            {
              id: 'public',
              type: 'barHorizontalSmall',
              data: getDataset({
                id: 'I14',
                // pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id
                // more: 'public'
              }),
              padding: {
                top: 400,
                bottom: 10,
                left: 10,
                right: 0
              },
              style: {
                width: 350,
                height: 600,
                top: 45,
                left: 30
              },
              domainPadding: { x: [0, 100], y: [150, 0] },
              labelContent: [
                {
                  text: [
                    chartLabels.percentageOfDataUsedForMiningLine1,
                    chartLabels.percentageOfDataUsedForMiningLine2
                  ],
                  // text: ['% of data', 'used for mining'],
                  x: 350,
                  y: 450,
                  textAnchor: 'end',
                  style: { ...TYPEFACE.body }
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                dy: 10,
                verticalAnchor: 'end'
              },
              additional: {
                isFlipped: true,
                barWidth: 20
              }
            },
            {
              id: 'private-label',
              type: 'label',
              padding: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
              },
              style: {
                width: 375,
                height: 50,
                top: 640,
                left: 1020
              },
              labelContent: [
                {
                  text: chartLabels.percentageOfRespondents,
                  // text: ['% of respondents'],
                  x: 0,
                  y: 20,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.header }
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                dy: 0,
                textAnchor: 'start',
                verticalAnchor: 'end'
              },
              additional: {
                // style: {
                //   border: '1px dotted green'
                // }
              }
            },
            {
              id: 'private',
              type: 'barHorizontalSmall',
              data: getDataset({
                id: 'I14',
                // pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id
                // more: 'private'
              }),
              padding: {
                top: 400,
                bottom: 20,
                left: 0,
                right: 0
              },
              style: {
                width: 350,
                height: 600,
                top: 45,
                left: 1020
              },
              domainPadding: { x: [0, 0], y: [0, 80] },
              labelContent: [
                {
                  text: [
                    chartLabels.percentageOfDataUsedForMiningLine1,
                    chartLabels.percentageOfDataUsedForMiningLine2
                  ],
                  // text: ['% of data', 'used for mining'],
                  x: 0,
                  y: 355,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.body }
                },
                {
                  text: '50%+',
                  x: 5,
                  y: 400,
                  textAnchor: 'start'
                },
                {
                  text: '40%',
                  x: 5,
                  y: 445,
                  textAnchor: 'start'
                },
                {
                  text: '30%',
                  x: 5,
                  y: 490,
                  textAnchor: 'start'
                },
                {
                  text: '20%',
                  x: 5,
                  y: 535,
                  textAnchor: 'start'
                },
                {
                  text: '0-10%',
                  x: 5,
                  y: 580,
                  textAnchor: 'start'
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                dy: 8,
                verticalAnchor: 'end'
              },
              additional: {
                barWidth: 40
              }
            }
          ]);
          break;
        case 'data-5':
          setContent([
            {
              id: 'data-location',
              type: 'barStandard',
              data: getDataset({
                id: 'DA1',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id
              }),
              padding: {
                top: 0,
                bottom: 30,
                left: 0,
                right: 100
              },
              style: {
                width: 540,
                height: 675,
                top: 0,
                left: 750
              },
              domain: { y: [0, 100] },
              domainPadding: { x: [100, 100], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.dataLocation,
                  // text: 'Data Location',
                  x: 20,
                  y: 660,
                  textAnchor: 'start'
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                dy: 10,
                textAnchor: 'start',
                verticalAnchor: 'start'
              },
              additional: {
                isAltColor: true,
                isWeighted: true,
                barWidth: 500,
                cornerRadius: { top: 10, bottom: 10 },
                textAnchor: 'start',
                verticalAnchor: 'end'
              }
            },
            {
              id: 'preference',
              type: 'barEmbeddedStandard',
              data: getDataset({
                id: 'D252627',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id
              }),
              padding: {
                top: 0,
                bottom: 30,
                left: 0,
                right: 15
              },
              style: {
                width: 750,
                height: 675,
                top: 0,
                left: 0
              },
              domain: { y: [0, 86] },
              domainPadding: { x: [320, 200], y: [0, 5] },
              labelContent: [
                {
                  text: chartLabels.dataScientistPreference,
                  // text: 'Data Scientist Preference',
                  x: 30,
                  y: 660,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.header }
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                dy: 10,
                textAnchor: 'start',
                verticalAnchor: 'start'
              },
              additional: {
                isAlt: true,
                isAltColor: false,
                isWeighted: true,
                cornerRadius: { top: 10, bottom: 10 },
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted red'
                // }
              }
            },
            {
              id: 'advantages',
              type: 'barEmbeddedList',
              data: getDataset({
                id: 'D252627',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id
              }),
              padding: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
              },
              style: {
                width: 200,
                height: 500,
                top: 170,
                left: 50
              },
              domain: { y: [0, 100] },
              domainPadding: { x: [50, 100], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.advantages,
                  // text: 'Advantages',
                  x: 0,
                  y: 10,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.header }
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                dy: -10,
                dx: 0,
                textAnchor: 'start',
                verticalAnchor: 'end'
              },
              additional: {
                sortKey: 'responseCount',
                barWidth: 10,
                cornerRadius: { top: 0, bottom: 0 },
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted red'
                // }
              }
            },
            {
              id: 'disadvantages',
              type: 'barEmbeddedList',
              data: getDataset({
                id: 'D252627',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id
              }),
              padding: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
              },
              style: {
                width: 200,
                height: 500,
                top: 170,
                left: 260
              },
              domain: { y: [0, 100] },
              domainPadding: { x: [50, 100], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.disadvantages,
                  // text: 'Disadvantages',
                  x: 0,
                  y: 10,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.header }
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                dy: -10,
                dx: 0,
                textAnchor: 'start',
                verticalAnchor: 'end'
              },
              additional: {
                sortKey: 'responseCount',
                barWidth: 10,
                cornerRadius: { top: 0, bottom: 0 },
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted red'
                // }
              }
            }
          ]);
          break;
        case 'data-6':
          setContent([
            {
              id: 'data-location',
              type: 'barStandard',
              data: getDataset({
                id: 'DA1',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionDefault
              }),
              padding: {
                top: 0,
                bottom: 30,
                left: 0,
                right: 100
              },
              style: {
                width: 540,
                height: 675,
                top: 0,
                left: 750
              },
              domain: { y: [0, 100] },
              domainPadding: { x: [100, 100], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.dataLocation,
                  // text: 'Data Location',
                  x: 20,
                  y: 660,
                  textAnchor: 'start'
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                dy: 10,
                textAnchor: 'start',
                verticalAnchor: 'start'
              },
              additional: {
                isWeighted: true,
                barWidth: 500,
                cornerRadius: { top: 10, bottom: 10 },
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted red'
                // }
              }
            }
          ]);
          break;
        case 'cost-1':
          setContent([
            {
              id: 'cost-overview',
              type: 'barCost',
              // data: costData,
              data: getDataset({
                id: 'I10',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryContext?.id
              }),
              padding: {
                top: 0,
                bottom: 40,
                left: 290, // PROBLEM: 300
                right: 290 // PROBLEM: 300
              },
              style: {
                width: 600,
                height: 515,
                top: 160,
                left: 110
              },
              domain: { y: [0, 110] },
              domainPadding: { x: [100, 100], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.infrastructureUsage,
                  // text: 'Infrastructure usage',
                  x: 30,
                  y: 500,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.header }
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                // dy: 80,
                // dx: 0,
                dx: -240,
                textAnchor: 'start',
                verticalAnchor: 'start'
              },
              additional: {
                isWeighted: false,
                barWidth: 500,
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted red'
                // }
              }
            }
          ]);
          break;
        case 'cost-2':
        case 'cost-3':
          setContent([
            {
              id: 'cost-overview',
              type: 'barCost',
              // data: costData,
              data: getDataset({
                id: 'I10',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryContext?.id
              }),
              padding: {
                top: 0,
                bottom: 40,
                left: 140, // Problem: 125
                right: 140 // Problem: 125
              },
              style: {
                width: 275,
                height: 515,
                top: 160,
                left: 0
              },
              domain: { y: [0, 110] },
              domainPadding: { x: [100, 100], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.infrastructureUsage,
                  // text: 'Infrastructure usage',
                  x: 30,
                  y: 500,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.bodyM }
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                // dy: 50,
                dx: -90,
                textAnchor: 'start',
                verticalAnchor: 'start'
              },
              additional: {
                isAlt: true,
                isWeighted: false,
                barWidth: 200,
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted red'
                // }
              }
            },
            {
              id: 'cost-overview',
              type: 'barCostSeparate',
              data: getDataset({
                id: 'I10',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryContext?.id
              }),
              padding: {
                top: 190,
                bottom: 25,
                left: 30, // 30
                right: 110 // 110
              },
              style: {
                width: 500,
                height: 670,
                top: 0,
                left: 275
              },
              domain: { y: [0, 100] },
              domainPadding: { x: [100, 100], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.publicCloudTitle,
                  // text: 'Public Cloud',
                  x: 25,
                  y: 660,
                  textAnchor: 'start',
                  verticalAnchor: 'start',
                  style: { ...TYPEFACE.header }
                },
                {
                  // text: 'On-Prem, Colo, or Private Cloud',
                  text: chartLabels.onPrem,
                  // text: 'On-Prem',
                  x: 220,
                  y: 660,
                  textAnchor: 'start',
                  verticalAnchor: 'start',
                  style: { ...TYPEFACE.header }
                }
              ],
              labelSettings: {
                dy: 10,
                backgroundPadding: 0,
                textAnchor: 'start',
                verticalAnchor: 'start'
              },
              additional: {
                isWeighted: false,
                barWidth: 175,
                textAnchor: 'start',
                verticalAnchor: 'end',
                style: {
                  borderLeft: '1px solid #000'
                }
              }
            }
          ]);
          break;
        case 'cost-4':
          setContent([
            {
              id: 'cost-overview',
              type: 'barCost',
              data: getDataset({
                id: 'I10',
                pageId: id,
                sizeId: sizeContext?.id,
                regionId: regionContext?.id,
                industryId: industryDefault
              }),
              padding: {
                top: 0,
                bottom: 40,
                left: 290, // PROBLEM: 300
                right: 290 // PROBLEM: 300
              },
              style: {
                width: 600,
                height: 515,
                top: 160,
                left: 750
              },
              domain: { y: [0, 110] },
              domainPadding: { x: [100, 100], y: [0, 0] },
              labelContent: [
                {
                  text: chartLabels.infrastructureUsage,
                  // text: 'Infrastructure usage',
                  x: 30,
                  y: 500,
                  textAnchor: 'start',
                  style: { ...TYPEFACE.bodyM }
                }
              ],
              labelSettings: {
                backgroundPadding: 0,
                // dy: 90,
                dx: -240,
                textAnchor: 'start',
                verticalAnchor: 'start'
              },
              additional: {
                isWeighted: false,
                barWidth: 500,
                textAnchor: 'start',
                verticalAnchor: 'end'
                // style: {
                //   border: '1px dotted red'
                // }
              }
            }
          ]);
          break;
        default:
          setContent([]);
      }
    }
  }, [
    id,
    sizeContext,
    regionContext,
    industryContext,
    onDisclaimerToggle,
    onDisclaimerSizeToggle,
    langContext
  ]);

  return (
    <div className="visualization" style={style}>
      {content &&
        content.map((graph: GraphProps, index: number) => {
          return <Graph key={'graph-' + index} content={graph} />;
        })}
    </div>
  );
};

export default Visualization;
