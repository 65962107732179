import { VictoryLabel } from 'victory';

// https://github.com/FormidableLabs/victory/issues/551
// https://formidable.com/open-source/victory/gallery/custom-tooltip-labels/
// https://www.frankcalise.com/static-methods-in-functional-components
const GraphEmbeddedListLabel = (props: any) => {
  // const { datum, text } = props;
  const { dxOffset, dyOffset, typeface } = props;

  const labelStyle = [
    {
      ...typeface.bodyL,
      fill: '#000000',
      textTransform: 'none'
    },
    {
      ...typeface.bodyL,
      fill: '#000000',
      textTransform: 'none'
    },
    { visibility: 'hidden' }
  ];

  const numberStyle = [
    { visibility: 'hidden' },
    { visibility: 'hidden' },
    {
      ...typeface.headerXL,
      fill: '#ffffff'
    }
  ];

  let thisDyOffset = 0;
  if (dyOffset) {
    thisDyOffset = dyOffset;
  }
  let thisDxOffset = 0;
  if (dxOffset) {
    thisDxOffset = dxOffset;
  }

  return (
    <g>
      <VictoryLabel {...props} x={0} style={labelStyle} />
      <VictoryLabel
        {...props}
        x={0}
        dx={thisDxOffset}
        dy={thisDyOffset}
        style={numberStyle}
      />
      {/* <VictoryLabel {...props} x={10} dx={5} dy={-52} style={numberStyle} /> */}
    </g>
  );
};

export default GraphEmbeddedListLabel;
