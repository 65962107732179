import Data from '../data/data.json';
import TextEnUs from '../data/text.en-us.json';
import TextDeDe from '../data/text.de-de.json';
import TextEnGb from '../data/text.en-gb.json';
import TextEsEs from '../data/text.es-es.json';
import TextEsXl from '../data/text.es-xl.json';
import TextFrFr from '../data/text.fr-fr.json';
import TextItIt from '../data/text.it-it.json';
import TextJaJp from '../data/text.ja-jp.json';
import TextKoKr from '../data/text.ko-kr.json';
import TextPtBr from '../data/text.pt-br.json';
import TextRuRu from '../data/text.ru-ru.json';
import TextZhTw from '../data/text.zh-tw.json';

// TODO: Consider dynamic imports, but would require rearchitecting the langContext
// https://www.youtube.com/watch?v=ddVm53j80vc

export const JSON_DATA: Record<string, any> = Data;
export const JSON_TEXT: Record<string, any> = {
  'en-us': TextEnUs,
  'de-de': TextDeDe,
  'en-gb': TextEnGb,
  'es-es': TextEsEs,
  'es-xl': TextEsXl,
  'fr-fr': TextFrFr,
  'it-it': TextItIt,
  'ja-jp': TextJaJp,
  'ko-kr': TextKoKr,
  'pt-br': TextPtBr,
  'ru-ru': TextRuRu,
  'zh-tw': TextZhTw
};
