import { JSON_DATA, JSON_TEXT } from '../../constants';
import Dropdown from '../Dropdown';
import { useRegion } from '../../contexts/RegionContext';
import { useIndustry } from '../../contexts/IndustryContext';
import { useSize } from '../../contexts/SizeContext';
import { useLang } from '../../contexts/LangContext';
import { FilterOverrideDataType } from '../../data/types';

type FilterGroupProps = {
  hideFilter?: boolean;
  additionalFilter?: string[];
  disabled?: boolean;
  filterOverride?: any;
};

const FilterGroup = ({
  hideFilter = false,
  additionalFilter,
  disabled = false,
  filterOverride = null
}: FilterGroupProps) => {
  const regionContext = useRegion();
  const sizeContext = useSize();
  const langContext = useLang();
  const industryContext = useIndustry();

  // Return null if hideFilter = true
  if (hideFilter || !langContext) {
    return null;
  }

  const groupLabel = JSON_TEXT[langContext.id].general.filterBy;
  const groupLabelPrint = JSON_TEXT[langContext.id].general.filterByPrint;

  const getDisabled = (
    id: string,
    defaultValue: boolean | undefined
  ): boolean | undefined => {
    if (filterOverride) {
      let selectedFilter = filterOverride.find(
        (thisFilter: FilterOverrideDataType) => {
          return thisFilter.id === id;
        }
      );
      if (selectedFilter) {
        return selectedFilter.disabled;
      }
    } else {
      return defaultValue;
    }
  };

  return (
    <div className="filter-group">
      <h3>
        <span className="normal">{groupLabel}</span>
        <span className="print">{groupLabelPrint}</span>
      </h3>
      <Dropdown
        key="filter-size"
        disabled={getDisabled('size', disabled)}
        value={sizeContext?.id}
        config={JSON_DATA.filters.size}
        onChange={(e) => {
          sizeContext?.setSize({
            id: e.target.value,
            label: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text
          });
        }}
      />
      <Dropdown
        key="filter-region"
        disabled={disabled}
        value={regionContext?.id}
        config={JSON_DATA.filters.region}
        onChange={(e) => {
          regionContext?.setRegion({
            id: e.target.value,
            label: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text
          });
        }}
      />
      {additionalFilter &&
        additionalFilter.map((filterId: string, index: number) => {
          const config = JSON_DATA.filters[filterId];
          // Only alternative is industry
          if (config && config.id === 'industry') {
            return (
              <div className="additional" key="filter-industry">
                <Dropdown
                  disabled={disabled}
                  value={industryContext?.id}
                  config={JSON_DATA.filters.industry}
                  onChange={(e) => {
                    industryContext?.setIndustry({
                      id: e.target.value,
                      label:
                        e.nativeEvent.target[e.nativeEvent.target.selectedIndex]
                          .text
                    });
                  }}
                />
              </div>
            );
          } else {
            return null;
          }
        })}
    </div>
  );
};

export default FilterGroup;
