type ButtonProps = {
  label: string;
  detail?: string;
  onClick?: (any: any) => void;
  disabled?: boolean;
};

const Button = ({ label, detail, onClick, disabled = false }: ButtonProps) => {
  return (
    <div className="button">
      {detail && <em>{detail}</em>}
      <button onClick={onClick} disabled={disabled}>
        <div dangerouslySetInnerHTML={{ __html: label }} />
      </button>
    </div>
  );
};

export default Button;
