import { ReactNode, createContext, useReducer, useContext } from 'react';
import { JSON_DATA } from '../constants';

type Action =
  | { type: 'CHANGE_LANG'; payload: languageType }
  | { type: 'DEFAULT'; payload: languageType };
type State = {
  id: string;
  label: string;
};
type LangProviderProps = {
  children: ReactNode;
};
type langContextType = {
  id: string;
  label: string;
  setLang: (lang: languageType) => void;
};
type languageType = {
  id: string;
  label: string;
};

const langReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'CHANGE_LANG': {
      localStorage.setItem('language', JSON.stringify(action.payload));
      return {
        id: action.payload.id,
        label: action.payload.label
      };
    }
    // case 'DEFAULT': {
    //   return { lang: 'en-us' };
    // }
    default: {
      throw new Error(`Unhandled action type}`);
    }
  }
};

const LangContext = createContext<langContextType | undefined>(undefined);

const LangProvider = ({ children }: LangProviderProps) => {
  const localState = JSON.parse(localStorage.getItem('language') || '{}');

  // Pull initiate state from dataset
  const initialState = JSON_DATA.filters.language.default;

  const [state, dispatch] = useReducer(langReducer, localState || initialState);

  // Check for empty local
  if (Object.keys(localState).length === 0) {
    // console.log('[LangContext] localState language is empty, set initialState');
    document.documentElement.lang = initialState.id; // Set lang tag on root html to default lang id
    dispatch({ type: 'CHANGE_LANG', payload: initialState });
  }

  // Check if localState actually has valid values
  if (Object.keys(localState).length > 0) {
    // Pull values from dataset
    const validValues = JSON_DATA.filters.language.options;
    const result = validValues.find(({ id }: any) => id === localState.id);
    if (!result) {
      document.documentElement.lang = initialState.id; // Set lang tag on root html to lang id
      dispatch({ type: 'CHANGE_LANG', payload: initialState });
    } else {
      document.documentElement.lang = localState.id; // Set lang tag on root html to localState id
    }
  }

  const setLang = (newLang: languageType) => {
    document.documentElement.lang = newLang.id; // Set lang tag on root html to lang id
    dispatch({ type: 'CHANGE_LANG', payload: newLang });
  };

  return (
    <LangContext.Provider
      value={{
        id: state.id,
        label: state.label,
        setLang
      }}
    >
      {children}
    </LangContext.Provider>
  );
};

const useLang = () => useContext(LangContext);

export { LangProvider, useLang };
