import { ReactNode, createContext, useReducer, useContext } from 'react';
import { JSON_DATA } from '../constants';

type Action =
  | { type: 'CHANGE_REGION'; payload: regionType }
  | { type: 'DEFAULT'; payload: regionType };
type State = {
  id: string;
  label: string;
};
type RegionProviderProps = {
  children: ReactNode;
};
type regionContextType = {
  id: string;
  label: string;
  setRegion: (region: regionType) => void;
};
type regionType = {
  id: string;
  label: string;
};

const regionReducer = (state: State, action: Action) => {
  //console.log('regionReducer action', action);
  switch (action.type) {
    case 'CHANGE_REGION': {
      localStorage.setItem('region', JSON.stringify(action.payload));
      return {
        id: action.payload.id,
        label: action.payload.label
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

const RegionContext = createContext<regionContextType | undefined>(undefined);

const RegionProvider = ({ children }: RegionProviderProps) => {
  const localState = JSON.parse(localStorage.getItem('region') || '{}');

  // Pull initiate state from dataset
  const initialState = JSON_DATA.filters.region.default;

  const [state, dispatch] = useReducer(
    regionReducer,
    localState || initialState
  );

  // Check for empty local
  if (Object.keys(localState).length === 0) {
    console.log('[RegionContext] localState region is empty, set initialState');
    dispatch({ type: 'CHANGE_REGION', payload: initialState });
  }

  // Check if localState actually has valid values
  if (Object.keys(localState).length > 0) {
    // Pull values from dataset
    const validValues = JSON_DATA.filters.region.options;
    const result = validValues.find(({ id }: any) => id === localState.id);
    if (!result) {
      dispatch({ type: 'CHANGE_REGION', payload: initialState });
    }
  }

  const setRegion = (newRegion: regionType) => {
    dispatch({ type: 'CHANGE_REGION', payload: newRegion });
  };

  return (
    <RegionContext.Provider
      value={{
        id: state.id,
        label: state.label,
        setRegion
      }}
    >
      {children}
    </RegionContext.Provider>
  );
};

const useRegion = () => useContext(RegionContext);

export { RegionProvider, useRegion };
