import { ReactNode, createContext, useReducer, useContext } from 'react';
import { JSON_DATA } from '../constants';

type Action =
  | { type: 'CHANGE_SIZE'; payload: sizeType }
  | { type: 'DEFAULT'; payload: sizeType };
type State = {
  id: string;
  label: string;
};
type SizeProviderProps = {
  children: ReactNode;
};
type sizeContextType = {
  id: string;
  label: string;
  setSize: (size: sizeType) => void;
};
type sizeType = {
  id: string;
  label: string;
};

const filterReducer = (state: State, action: Action) => {
  //console.log('filterReducer action', action);
  switch (action.type) {
    case 'CHANGE_SIZE': {
      localStorage.setItem('size', JSON.stringify(action.payload));
      return {
        id: action.payload.id,
        label: action.payload.label
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

const SizeContext = createContext<sizeContextType | undefined>(undefined);

const SizeProvider = ({ children }: SizeProviderProps) => {
  const localState = JSON.parse(localStorage.getItem('size') || '{}');

  // Pull initiate state from dataset
  const initialState = JSON_DATA.filters.size.default;

  const [state, dispatch] = useReducer(
    filterReducer,
    localState || initialState
  );

  // Check for empty local
  if (Object.keys(localState).length === 0) {
    console.log('[SizeContext] localState size is empty, set initialState');
    dispatch({ type: 'CHANGE_SIZE', payload: initialState });
  }

  // Check if localState actually has valid values
  if (Object.keys(localState).length > 0) {
    // Pull values from dataset
    const validValues = JSON_DATA.filters.size.options;
    const result = validValues.find(({ id }: any) => id === localState.id);
    if (!result) {
      dispatch({ type: 'CHANGE_SIZE', payload: initialState });
    }
  }

  const setSize = (newSize: sizeType) => {
    dispatch({ type: 'CHANGE_SIZE', payload: newSize });
  };

  return (
    <SizeContext.Provider
      value={{
        id: state.id,
        label: state.label,
        setSize
      }}
    >
      {children}
    </SizeContext.Provider>
  );
};

const useSize = () => useContext(SizeContext);

export { SizeProvider, useSize };
