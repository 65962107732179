const TYPEFACE_DEFAULT = {
  header: {
    fontFamily: 'MetricHPEBold',
    fontWeight: 'bold',
    fontSize: 16,
    textTransform: 'uppercase'
  },
  headerM: {
    fontFamily: 'MetricHPEBold',
    fontWeight: 'bold',
    fontSize: 20,
    textTransform: 'uppercase'
  },
  headerL: {
    fontFamily: 'MetricHPEBold',
    fontWeight: 'bold',
    fontSize: 24,
    textTransform: 'uppercase'
  },
  headerXL: {
    fontFamily: 'MetricHPEBold',
    fontWeight: 'bold',
    fontSize: 32,
    textTransform: 'uppercase'
  },
  headerXXL: {
    fontFamily: 'MetricHPEBold',
    fontWeight: 'bold',
    fontSize: 50,
    textTransform: 'uppercase'
  },
  headerXXXL: {
    fontFamily: 'MetricHPEBold',
    fontWeight: 'bold',
    fontSize: 60,
    textTransform: 'uppercase'
  },
  headerXXXXL: {
    fontFamily: 'MetricHPEBold',
    fontWeight: 'bold',
    fontSize: 70,
    textTransform: 'uppercase'
  },
  body: {
    fontFamily: 'MetricHPERegular',
    fontSize: 14,
    textTransform: 'uppercase'
  },
  bodyM: {
    fontFamily: 'MetricHPERegular',
    fontSize: 16,
    textTransform: 'uppercase'
  },
  bodyL: {
    fontFamily: 'MetricHPERegular',
    fontSize: 18,
    textTransform: 'uppercase'
  }
};

const TYPEFACE_RU = {
  header: {
    fontFamily: 'Arial',
    fontWeight: 'bold',
    fontSize: 12,
    textTransform: 'uppercase'
  },
  headerM: {
    fontFamily: 'Arial',
    fontWeight: 'bold',
    fontSize: 18,
    textTransform: 'uppercase'
  },
  headerL: {
    fontFamily: 'Arial',
    fontWeight: 'bold',
    fontSize: 20,
    textTransform: 'uppercase'
  },
  headerXL: {
    fontFamily: 'Arial',
    fontWeight: 'bold',
    fontSize: 24,
    textTransform: 'uppercase'
  },
  headerXXL: {
    fontFamily: 'Arial',
    fontWeight: 'bold',
    fontSize: 30,
    textTransform: 'uppercase'
  },
  headerXXXL: {
    fontFamily: 'Arial',
    fontWeight: 'bold',
    fontSize: 40,
    textTransform: 'uppercase'
  },
  headerXXXXL: {
    fontFamily: 'Arial',
    fontWeight: 'bold',
    fontSize: 60,
    textTransform: 'uppercase'
  },
  body: {
    fontFamily: 'Arial',
    fontSize: 10,
    textTransform: 'uppercase'
  },
  bodyM: {
    fontFamily: 'Arial',
    fontSize: 12,
    textTransform: 'uppercase'
  },
  bodyL: {
    fontFamily: 'Arial',
    fontSize: 12,
    textTransform: 'uppercase'
  }
};

export const getTypeface = (lang: string) => {
  if (lang === 'ru-ru') {
    console.log('STYLE RUSSIAN DETECTED!');
    return TYPEFACE_RU;
  }
  return TYPEFACE_DEFAULT;
};
