import { GraphProps } from '../../data/types';
import { JSON_TEXT } from '../../constants';
import {
  VictoryBar,
  VictoryContainer,
  VictoryStack,
  VictoryGroup,
  VictoryLabel
} from 'victory';
import GraphEmbeddedListLabel from './GraphEmbeddedListLabel';
import { useLang } from '../../contexts/LangContext';
import { getTypeface } from '../../utils/style';

type thisProps = {
  content: GraphProps;
};

const Graph = ({ content }: thisProps) => {
  const {
    id,
    type,
    data,
    padding,
    domain,
    domainPadding,
    additional,
    labelContent,
    labelSettings,
    style
  } = content;

  const langContext = useLang();
  if (!langContext) {
    return null;
  }

  // Established the correct JSON_TEXT for language pull
  const chartLabels = JSON_TEXT[langContext?.id].chartLabels;

  // Load in correct Typeface
  const TYPEFACE = getTypeface(langContext?.id);

  if (data) {
    if (Object.keys(data).length === 0) {
      return null;
    } else {
      // console.log('  [Graph] id', id, content, visualizationFilter);
    }
  }

  // Separate out label to be multiple rows
  // const getLabel = ({ datum }: { datum: any }) => {
  const getLabel: any = (datum: any) => {
    const label = datum.x;
    const thisY = Math.round(datum.y);

    if (datum.y === 0) {
      return ['', '', ''];
    }

    // Determine if label is string
    if (typeof label === 'string') {
      let labelArray = label.split('\n');
      if (labelArray.length > 1) {
        return [labelArray[0], labelArray[1], thisY + '%'];
      } else {
        return ['', datum.x, thisY + '%'];
      }
    } else {
      return ['', '', ''];
    }
  };

  // Returns label array of x with data at the end
  // Fourth item is guaranteed to be data
  const getLabelStringWithData: any = (datum: any) => {
    const label = datum.x;
    const thisY = Math.round(datum.y);

    if (datum.x && datum.y) {
      if (datum.y === 0) {
        return [];
      }
      // Determine if label is string
      if (typeof label === 'string') {
        const labelArray = label.split('\n');
        return [labelArray[0], labelArray[1], labelArray[2], `${thisY}%`];
      } else {
        return [label];
      }
    }
  };

  // Separate out label to be multiple rows
  // Isolated for barEmbeddedDetailList, just in case
  const getLabelTip: any = (datum: any) => {
    const label = datum.tip;
    const thisY = Math.round(datum.y);

    if (datum.y === 0) {
      return ['', '', ''];
    }

    // Determine if label is string
    if (typeof label === 'string') {
      let labelArray = label.split('\n');
      if (labelArray.length > 1) {
        return [labelArray[0], labelArray[1], thisY + '%'];
      } else {
        return ['', datum.tip, thisY + '%'];
      }
    } else {
      return ['', '', ''];
    }
  };

  // Returns array
  const getLabelCost: any = (datum: any) => {
    const label = datum.tip;

    if (datum.x && datum.y) {
      const thisY = Math.round(Number(datum.y));
      let thisYOutput = '';

      if (datum.y === 0) {
        return [];
      }

      if (thisY === 100) {
        thisYOutput = '';
      } else {
        thisYOutput = thisY + '%';
      }

      // Determine if label is string
      if (typeof label === 'string') {
        const labelArray = label.split('\n');
        if (labelArray.length > 1) {
          return [labelArray[0], labelArray[1], labelArray[2], thisYOutput];
        } else {
          return [label, '', '', thisYOutput];
        }
      } else {
        return [];
      }
    }
  };

  // Returns label array of x
  const getLabelString: any = (datum: any) => {
    const label = datum.x;
    if (datum.x && datum.y) {
      if (datum.y === 0) {
        return [];
      }
      // Determine if label is string
      if (typeof label === 'string') {
        const labelArray = label.split('\n');
        return labelArray;
      } else {
        return [label];
      }
    }
  };

  // Temp second version
  const getLabel2: any = (datum: any) => {
    // const label = datum.y;
    const label = Math.round(datum.y) + '%';
    // const thisY = 'xx% of respondent'; //Math.round(datum.responseCount);
    return [label];
  };

  // Temp second version
  const getLabelStack: any = (datum: any) => {
    const label = datum.tip;
    const newTip = Math.round(datum.y);
    if (datum.y === 0) {
      return [];
    }
    // Determine if label is string
    if (typeof label === 'string') {
      let labelArray = label.split('\n');
      if (labelArray.length > 1) {
        return [labelArray[0], labelArray[1], newTip + '%'];
      } else {
        return [labelArray[0], newTip + '%'];
      }
    } else {
      return ['', ''];
    }
  };

  const animationDuration: number = 500;
  const loadAnimationDuration: number = 250;
  const labelStyleWeighted = [
    {
      ...TYPEFACE.bodyM,
      fill: '#ffffff',
      left: 20
    },
    {
      ...TYPEFACE.bodyM,
      fill: '#ffffff',
      left: 20
    },
    {
      ...TYPEFACE.bodyM,
      fill: '#ffffff',
      left: 20
    },
    {
      ...TYPEFACE.headerXXXXL,
      fill: '#ffffff'
    }
  ];
  const labelStyleStandard = [
    {
      ...TYPEFACE.body,
      fill: '#ffffff',
      left: 20
    },
    {
      ...TYPEFACE.body,
      fill: '#ffffff',
      left: 20
    },
    {
      ...TYPEFACE.body,
      fill: '#ffffff',
      left: 20
    },
    {
      ...TYPEFACE.headerXL,
      fill: '#ffffff'
    }
  ];
  const labelStyleStacked = [
    {
      ...TYPEFACE.body,
      fill: '#000000'
    },
    {
      ...TYPEFACE.body,
      fill: '#000000'
    },
    {
      ...TYPEFACE.headerXL,
      fill: '#ffffff'
    }
  ];
  const labelStyleStackedHidden = [
    {
      ...TYPEFACE.body,
      visibility: 'hidden',
      display: 'none',
      fill: '#000000'
    },
    {
      ...TYPEFACE.body,
      visibility: 'hidden',
      fill: '#000000'
    },
    {
      ...TYPEFACE.headerL,
      fill: '#ffffff'
    }
  ];
  const labelStyleStackLocation = [
    {
      ...TYPEFACE.headerL,
      fill: '#ffffff'
    }
  ];
  const labelStyleList = [
    {
      ...TYPEFACE.bodyL,
      fill: '#000000',
      left: 0,
      textTransform: 'none'
    }
  ];
  const labelStyleH = [
    {
      ...TYPEFACE.bodyM,
      left: 20
    },
    {
      ...TYPEFACE.bodyM,
      left: 20
    },
    {
      ...TYPEFACE.headerXXXL,
      fill: '#000000'
    }
  ];
  const labelStyleHorSmall = [
    {
      ...TYPEFACE.headerM,
      fill: '#000000'
    }
  ];
  const labelStyleTitle = [
    {
      ...TYPEFACE.bodyM,
      fill: '#525252'
    }
  ];
  const labelStyleWorkload = [
    {
      ...TYPEFACE.bodyL,
      fill: '#000000',
      textTransform: 'none'
    }
  ];
  const labelStyleCost = [
    {
      ...TYPEFACE.bodyL,
      fill: '#000000'
    },
    {
      ...TYPEFACE.bodyL,
      fill: '#000000'
    },
    {
      ...TYPEFACE.bodyL,
      fill: '#ffffff'
    },
    {
      ...TYPEFACE.headerXXL,
      fill: '#ffffff'
    }
  ];
  const labelStyleCostSidebar = [
    {
      ...TYPEFACE.bodyM,
      fill: '#000000'
    },
    {
      ...TYPEFACE.bodyM,
      fill: '#000000'
    },
    {
      ...TYPEFACE.bodyM,
      fill: '#ffffff'
    },
    {
      ...TYPEFACE.headerXL,
      fill: '#ffffff'
    }
  ];

  let graphDataset: any = [];
  let titleDataset: any = [];
  // Custom data preparation for locationStack
  if (type === 'locationStack') {
    // console.log('[Graph] locationStack!', data);
    // Helper format association
    let formatAssociation: any = {
      r1: {
        // tip: '100%\nPublic Cloud',
        color: '#32dac8',
        altColor: '#e1e1e1'
      },
      r2: {
        // tip: 'Mostly\nPublic Cloud',
        color: '#7ff9e2',
        altColor: '#d4d4d4'
      },
      r3: {
        // tip: 'Equal\nMix',
        color: '#c6c6c2',
        altColor: '#e2e2e2'
      },
      r4: {
        // tip: 'Mostly\nOn-Prem',
        color: '#c140ff',
        altColor: '#c0c0c0'
      },
      r5: {
        // tip: '100%\nOn-Prem',
        color: '#7630ea',
        altColor: '#aeaeae'
      }
    };
    // Iterate through data
    for (const dataIndex in data) {
      const thisData = data[dataIndex];
      titleDataset.push(chartLabels[thisData.id]);
      // Iterate through content array
      for (const contentIndex in thisData.content) {
        const thisContent = thisData.content[contentIndex];
        // Create new result
        let thisResult: any = {};
        thisResult.id = thisData.id;
        // thisResult.label = thisData.tip;
        // thisResult.name = thisData.tip;
        // thisResult.tip = formatAssociation[thisContent.id].tip;
        thisResult.x = thisData.x;
        thisResult.y = thisContent.y;
        thisResult.responseCount = thisContent.responseCount;
        thisResult.color = formatAssociation[thisContent.id].color;
        thisResult.altColor = formatAssociation[thisContent.id].altColor;
        thisResult.responseCount = thisContent.responseCount;
        // console.log('[Graph] thisResult', thisResult);
        graphDataset.push([thisResult]);
      }
    }
    // console.log('[Graph] graphDataset', graphDataset);
  }

  // Custom data preparation for barRealities
  if (type === 'barRealities') {
    // Helper format association
    let formatAssociation: any = {
      r1infra: {
        tip: '\n' + chartLabels.publicCloud,
        // tip: '\nPublic Cloud x',
        color: '#32dac8',
        altColor: '#e1e1e1'
      },
      r2infra: {
        tip: chartLabels.onPremColoOrPrivateCloud,
        // tip: 'On-Prem, Colo,\nor Private Cloud',
        color: '#7630ea',
        altColor: '#aeaeae'
      },
      r1: {
        tip: chartLabels.allPublicCloud,
        // tip: '100%\nPublic Cloud',
        color: '#32dac8',
        altColor: '#e1e1e1'
      },
      r2: {
        tip: chartLabels.mostlyPublicCloud,
        // tip: 'Mostly\nPublic Cloud',
        color: '#7ff9e2',
        altColor: '#d4d4d4'
      },
      r3: {
        tip: chartLabels.equalMix,
        // tip: 'Equal\nMix',
        color: '#c6c6c2',
        altColor: '#e2e2e2'
      },
      r4: {
        tip: chartLabels.mostlyOnPrem,
        // tip: 'Mostly\nOn-Prem',
        color: '#c140ff',
        altColor: '#c0c0c0'
      },
      r5: {
        tip: chartLabels.allOnPrem,
        // tip: '100%\nOn-Prem',
        color: '#7630ea',
        altColor: '#aeaeae'
      }
    };
    // Iterate through data
    for (const dataIndex in data) {
      const thisData = data[dataIndex];
      // Iterate through content array
      for (const contentIndex in thisData.content) {
        const thisContent = thisData.content[contentIndex];
        // Create new result
        let thisResult: any = {};
        thisResult.tip = formatAssociation[thisContent.id].tip;
        thisResult.x = thisData.x;
        thisResult.y = thisContent.y;
        thisResult.responseCount = thisContent.responseCount;
        thisResult.color = formatAssociation[thisContent.id].color;
        thisResult.altColor = formatAssociation[thisContent.id].altColor;
        thisResult.responseCount = thisContent.responseCount;
        // console.log('[Graph] thisResult', thisResult);
        graphDataset.push([thisResult]);
      }
    }
    // console.log('[Graph] barRealities graphDataset', graphDataset);
  }

  // Custom data preparation for barCost
  if (type === 'barCost') {
    // Process data from getI10Results
    // Helper format association
    // TODO: Allow for more line break?
    const formatAssociation: any = {
      total: {
        x: chartLabels.totalPublicAndOnPrem,
        // x: 'Total public and on-prem',
        y: 100,
        tip: `${chartLabels.totalPublicAndOnPrem}`,
        // tip: 'Total public & on-prem\n',
        color: '#d8d8d8',
        cornerRadiusTop: 5,
        cornerRadiusBottom: 5,
        strokeWidth: 20,
        strokeColor: '#d8d8d8'
      },
      summaryUnused: {
        x: chartLabels.unused,
        // x: 'Unused',
        tip: `\n\n${chartLabels.unused}`,
        // tip: '\nUnused',
        cornerRadiusTop: 0,
        cornerRadiusBottom: 10,
        color: '#0e5265'
      }
    };
    // Add in total default
    const totalDefault = formatAssociation.total;
    graphDataset.push(totalDefault);

    // Iterate through data
    for (const dataIndex in data) {
      const thisData = data[dataIndex];
      // Look for id = 'summaryUnused'
      if (thisData.id === 'summaryUnused') {
        // Create new result
        let thisResult: any = formatAssociation.summaryUnused;
        thisResult.y = thisData.y;
        graphDataset.push(thisResult); // Add to graphDataset
      }
    }
    // console.log('[Graph] barCost graphDataset', graphDataset);
  }

  // Custom data preparation for barCostSeparate
  if (type === 'barCostSeparate') {
    // Process data from getI10Results
    const formatAssociation: any = {
      privateUnused: {
        x: 'private',
        tip: chartLabels.unused,
        // tip: 'Unused',
        cornerRadiusTopLeft: 0,
        cornerRadiusTopRight: 0,
        cornerRadiusBottomLeft: 10,
        cornerRadiusBottomRight: 10,
        color: '#0e5265',
        labelColor: '#ffffff',
        numberFontSize: 50,
        dy: 65
      },
      privateUsed: {
        x: 'private',
        tip: chartLabels.used,
        // tip: 'Used',
        cornerRadiusTopLeft: 0,
        cornerRadiusTopRight: 0,
        cornerRadiusBottomLeft: 0,
        cornerRadiusBottomRight: 0,
        color: '#7630ea',
        labelColor: '#ffffff',
        numberFontSize: 30,
        dy: 50
      },
      privateActivelyUsed: {
        x: 'private',
        tip: chartLabels.activelyUsed,
        // tip: 'Actively Used',
        cornerRadiusTopLeft: 10,
        cornerRadiusTopRight: 10,
        cornerRadiusBottomLeft: 0,
        cornerRadiusBottomRight: 0,
        color: '#c140ff',
        labelColor: '#ffffff',
        numberFontSize: 30,
        dy: 50
      },
      publicUnused: {
        x: 'public',
        tip: chartLabels.unused,
        // tip: 'Unused',
        cornerRadiusTopLeft: 0,
        cornerRadiusTopRight: 0,
        cornerRadiusBottomLeft: 10,
        cornerRadiusBottomRight: 10,
        color: '#0e5265',
        labelColor: '#ffffff',
        numberFontSize: 50,
        dy: 65
      },
      publicUsed: {
        x: 'public',
        tip: chartLabels.used,
        // tip: 'Used',
        cornerRadiusTopLeft: 0,
        cornerRadiusTopRight: 0,
        cornerRadiusBottomLeft: 0,
        cornerRadiusBottomRight: 0,
        color: '#32dbc8',
        labelColor: '#000000',
        numberFontSize: 30,
        dy: 50
      },
      publicActivelyUsed: {
        x: 'public',
        tip: chartLabels.activelyUsed,
        // tip: 'Actively Used',
        cornerRadiusTopLeft: 10,
        cornerRadiusTopRight: 10,
        cornerRadiusBottomLeft: 0,
        cornerRadiusBottomRight: 0,
        color: '#7ff9e2',
        labelColor: '#000000',
        numberFontSize: 30,
        dy: 50
      }
    };

    // Iterate through data
    for (const dataIndex in data) {
      const thisData = data[dataIndex];
      // Match to format
      if (formatAssociation[thisData.id]) {
        let thisResult: any = formatAssociation[thisData.id];
        thisResult.y = thisData.y;
        graphDataset.push([thisResult]); // Add to graphDataset
      }
    }
    // console.log('[Graph] barCostSeparate graphDataset', graphDataset);
  }

  // Custom data preparation for barEmbeddedDetailList
  if (type === 'barEmbeddedDetailList') {
    // Used by realities-3 and workload-1
    // Color sequence
    const formatAssociation: string[] = [
      '#b9cad1',
      '#b9cad1',
      '#7698a2',
      '#7698a2',
      '#0e5265',
      '#0e5265'
    ];
    // Iterate through data
    for (const dataIndex in data) {
      const thisData = data[dataIndex];
      // Create new result
      let thisResult: any = {};
      thisResult = thisData;
      thisResult.tip = chartLabels[thisData.x]; // Add in label tip, override x caused mutation problems
      const thisIndex = Number(dataIndex); // Odd, unsure why needed to cast to Number
      thisResult.color = formatAssociation[thisIndex];
      graphDataset.push(thisResult);
    }
    // console.log('[Graph] barEmbeddedDetailList graphDataset', graphDataset);
  }

  // Custom data preparation for contentList
  if (type === 'contentList') {
    // Content association
    const formatAssociation: any = {
      r1: {
        x: chartLabels.cloudExperienceExpectationsOnPremR1,
        // x: 'A consumption-based chargeback / metering\nmodel based on actual IT resources used',
        color: '#ffffff'
      },
      r2: {
        x: chartLabels.cloudExperienceExpectationsOnPremR2,
        // x: 'The ability to provision new workloads and\napplications in hours, if not minutes',
        color: '#ffffff'
      },
      r3: {
        x: chartLabels.cloudExperienceExpectationsOnPremR3,
        // x: 'The ability to rapidly scale up and scale down\ncapacity as needed',
        color: '#ffffff'
      },
      r4: {
        x: chartLabels.cloudExperienceExpectationsOnPremR4,
        // x: 'A user interface that is similar to\npublic cloud providers',
        color: '#ffffff'
      },
      r5: {
        x: chartLabels.cloudExperienceExpectationsOnPremR5,
        // x: 'Offloading responsibility of maintaining and\nupgrading infrastructure to a 3rd party',
        color: '#ffffff'
      },
      r6: {
        x: chartLabels.cloudExperienceExpectationsOnPremR6,
        // x: 'The ability to access specialized compute\ninstances (i.e. GPU intensive, RAM intensive)',
        color: '#ffffff'
      },
      r7: {
        x: chartLabels.cloudExperienceExpectationsOnPremR7,
        // x: 'The ability to access developer, IT ops, and\nanalytics tools and services',
        color: '#ffffff'
      },
      r8: {
        x: chartLabels.cloudExperienceExpectationsOnPremR8,
        // x: 'The ability to use AI/ML services that leverage\nparallel processing across compute instances',
        color: '#ffffff'
      },
      r9: {
        x: chartLabels.cloudExperienceExpectationsOnPremR9,
        // x: 'The ability to leverage automated workflows\nto trigger micro-services as they are needed',
        color: '#ffffff'
      }
    };
    // Iterate through data
    for (const dataIndex in data) {
      const thisData = data[dataIndex];
      // Create new result
      let thisResult: any = {};
      thisResult = thisData;
      const thisId = thisData.id;
      thisResult.x = formatAssociation[thisId].x;
      thisResult.color = formatAssociation[thisId].color;
      graphDataset.push(thisResult);
    }
    // console.log('[Graph] contentList graphDataset', graphDataset);
  }

  // Custom data preparation for barVertical
  if (type === 'barVertical') {
    // Content association
    const formatAssociation: any = {
      r1: {
        x: chartLabels.veryConcerned,
        // x: 'Very\nconcerned',
        color: '#0e5265'
      },
      r2: {
        x: chartLabels.somewhatConcerned,
        // x: 'Somewhat\nconcerned',
        color: '#7698a2'
      },
      r3: {
        x: chartLabels.notVeryConcerned,
        // x: 'Not very\nconcerned',
        color: '#b9cad1'
      },
      r4: {
        x: chartLabels.notAtAllConcerned,
        // x: 'Not at all\nconcerned',
        color: '#d8d8d8'
      },
      r5: {
        x: chartLabels.dontKnowUnsure,
        // x: "Don't know\nUnsure",
        color: '#d8d8d8'
      }
    };
    // Iterate through data
    for (const dataIndex in data) {
      const thisData = data[dataIndex];
      // Create new result
      let thisResult: any = {};
      thisResult = thisData;
      const thisId = thisData.id;
      thisResult.x = formatAssociation[thisId].x;
      thisResult.color = formatAssociation[thisId].color;
      graphDataset.push(thisResult);
    }
    // console.log('[Graph] barVertical graphDataset', graphDataset);
  }

  // Custom data preparation for barHorizontal
  if (type === 'barHorizontal') {
    // Content association
    const formatAssociation: any = {
      r1: {
        x: chartLabels.notUnderstood,
        //x: 'Not\nunderstood',
        color: '#d8d8d8'
      },
      r2: {
        x: chartLabels.reactiveNotProactive,
        // x: 'Reactive\nnot proactive',
        color: '#b9cad1'
      },
      r3: {
        x: chartLabels.thoughtfulButIncomplete,
        // x: 'Thoughtful\nbut incomplete',
        color: '#7698a2'
      },
      r4: {
        x: chartLabels.mature,
        // x: 'Mature',
        color: '#0e5265'
      }
    };
    // Iterate through data
    for (const dataIndex in data) {
      const thisData = data[dataIndex];
      // Create new result
      let thisResult: any = {};
      thisResult = thisData;
      const thisId = thisData.id;
      thisResult.x = formatAssociation[thisId].x;
      thisResult.color = formatAssociation[thisId].color;
      graphDataset.push(thisResult);
    }
    // console.log('[Graph] barHorizontal graphDataset', graphDataset);
  }

  // Custom data preparation for barHorizontalSmall
  if (type === 'barHorizontalSmall') {
    // Content association
    const formatAssociation: any = {
      r1: { x: '0-10%', color: '#d8d8d8' },
      r2: { x: '20%', color: '#cdd1d2' },
      r3: { x: '30%', color: '#bbc5c8' },
      r4: { x: '40%', color: '#8aa9b6' },
      r5: { x: '50% +', color: '#7698a2' }
    };
    switch (id) {
      case 'public':
        const publicData = data.public;
        // Iterate through data
        for (const dataIndex in publicData) {
          const thisData = publicData[dataIndex];
          // Create new result
          let thisResult: any = {};
          thisResult = thisData;
          const thisId = thisData.id;
          thisResult.x = formatAssociation[thisId].x;
          thisResult.color = formatAssociation[thisId].color;
          graphDataset.push(thisResult);
        }
        break;
      case 'private':
        const privateData = data.private;
        // Iterate through data
        for (const dataIndex in privateData) {
          const thisData = privateData[dataIndex];
          // Create new result
          let thisResult: any = {};
          thisResult = thisData;
          const thisId = thisData.id;
          thisResult.x = formatAssociation[thisId].x;
          thisResult.color = formatAssociation[thisId].color;
          graphDataset.push(thisResult);
        }
        break;
    }
    // console.log('[Graph] barHorizontalSmall graphDataset', graphDataset);
  }

  // Custom data preparation for barEmbeddedList
  if (type === 'barEmbeddedList' || type === 'barEmbeddedStandard') {
    // console.log('[Graph] barEmbeddedList id', id);
    // console.log('[Graph] barEmbeddedList data', data);
    // Content association
    const formatAssociation: any = {
      preference: {
        r1: {
          x: chartLabels.publicCloud,
          // x: 'Public Cloud',
          color: '#6dd7c9',
          altColor: '#333333',
          altWidth: 440
        },
        r2: {
          x: chartLabels.onPremColoOrPrivateCloud,
          // x: 'On-Prem, Colo,\nor Private Cloud',
          color: '#8d5ef4',
          altColor: '#040404',
          altWidth: 200
        }
      },
      advantages: {
        color: '#0e5265',
        altColor: '#ffffff'
      },
      disadvantages: {
        color: '#ffffff',
        altColor: '#7698a2'
      }
    };
    switch (id) {
      case 'preference':
        // Iterate through data
        const thisPreferenceSource = data.preference;
        let thisPreferenceResult: any = [];
        for (const dataIndex in thisPreferenceSource) {
          const thisData = thisPreferenceSource[dataIndex];
          // Create new result
          let thisObj: any = {};
          thisObj = thisData;
          thisObj.x = formatAssociation.preference[thisData.id].x;
          thisObj.color = formatAssociation.preference[thisData.id].color;
          thisObj.altColor = formatAssociation.preference[thisData.id].altColor;
          thisObj.altWidth = formatAssociation.preference[thisData.id].altWidth;
          thisPreferenceResult.push(thisObj);
        }
        graphDataset = thisPreferenceResult;
        break;
      case 'advantages':
        const thisDataAdvantagesSource = data.advantages;
        let thisAdvantagesResult: any = [];
        for (const dataIndex in thisDataAdvantagesSource) {
          const thisData = thisDataAdvantagesSource[dataIndex];
          // Create new result
          let thisObj: any = {};
          // thisObj = thisData;
          thisObj.x = chartLabels[thisData.x];
          thisObj.y = thisData.y;
          thisObj.responseCount = thisData.responseCount;
          thisObj.color = formatAssociation.advantages.color;
          thisObj.altColor = formatAssociation.advantages.altColor;
          thisAdvantagesResult.push(thisObj);
        }
        graphDataset = thisAdvantagesResult;
        break;
      case 'disadvantages':
        const thisDataDisadvantagesSource = data.disadvantages;
        let thisDisadvantagesResult: any = [];
        for (const dataIndex in thisDataDisadvantagesSource) {
          const thisData = thisDataDisadvantagesSource[dataIndex];
          // Create new result
          let thisObj: any = {};
          // thisObj = thisData;
          thisObj.x = chartLabels[thisData.x];
          thisObj.y = thisData.y;
          thisObj.responseCount = thisData.responseCount;
          thisObj.color = formatAssociation.disadvantages.color;
          thisObj.altColor = formatAssociation.disadvantages.altColor;
          thisDisadvantagesResult.push(thisObj);
        }
        graphDataset = thisDisadvantagesResult;
        break;
    }
    // console.log('[Graph] barEmbeddedList graphDataset', id, graphDataset);
  }

  // Custom data preparation for barStandard
  if (type === 'barStandard') {
    // console.log('[Graph] barStandard id', id);
    // console.log('[Graph] barStandard data', data);
    // Content association
    const formatAssociation: any = {
      r1: {
        x: chartLabels.publicCloud,
        // x: 'Public Cloud',
        color: '#32dac8',
        altColor: '#d8d8d8',
        altWidth: 170
      },
      r2: {
        x: chartLabels.onPremColoOrPrivateCloud,
        // x: 'On-Prem, Colo,\nor Private Cloud',
        color: '#7630ea',
        altColor: '#d8d8d8',
        altWidth: 430
      }
    };
    if (id === 'data-location') {
      // Iterate through data
      let thisResult: any = [];
      for (const dataIndex in data) {
        const thisData = data[dataIndex];
        // Create new result
        let thisObj: any = {};
        thisObj = thisData;
        thisObj.x = formatAssociation[thisData.id].x;
        thisObj.tip = formatAssociation[thisData.id].x + '%';
        thisObj.color = formatAssociation[thisData.id].color;
        thisObj.altColor = formatAssociation[thisData.id].altColor;
        thisObj.altWidth = formatAssociation[thisData.id].altWidth;
        thisResult.push(thisObj);
      }
      graphDataset = thisResult;
    } else {
      graphDataset = data;
    }
    // console.log('[Graph] barStandard graphDataset', id, graphDataset);
  }

  return (
    <div className="graph" id={id} style={style}>
      {/* bar: default test */}
      {type === 'bar' && (
        <VictoryBar data={data} labels={({ datum }) => [datum.x, datum.y]} />
      )}

      {/* label: generic usage */}
      {type === 'label' && (
        <VictoryGroup
          width={Number(style?.width)}
          height={Number(style?.height)}
          animate={{
            duration: animationDuration,
            easing: 'cubicInOut'
          }}
          containerComponent={
            <VictoryContainer
              responsive={false}
              width={Number(style?.width)}
              height={Number(style?.height)}
              style={additional.style}
            />
          }
        >
          {labelContent &&
            labelContent.map((thisLabel, labelIndex) => {
              return (
                <VictoryLabel
                  key={'label-' + labelIndex.toString()}
                  text={thisLabel.text}
                  x={thisLabel.x}
                  y={thisLabel.y}
                  textAnchor={thisLabel.textAnchor}
                  style={thisLabel.style ? thisLabel.style : labelStyleTitle}
                />
              );
            })}
        </VictoryGroup>
      )}

      {/* barVertical: Used for D7 */}
      {type === 'barVertical' && (
        <VictoryGroup
          domain={domain}
          domainPadding={domainPadding}
          padding={padding}
          width={Number(style?.width)}
          height={Number(style?.height)}
          animate={{
            duration: animationDuration,
            easing: 'cubicInOut'
          }}
          containerComponent={
            <VictoryContainer
              responsive={false}
              width={Number(style?.width)}
              height={Number(style?.height)}
              style={additional.style}
            />
          }
        >
          <VictoryStack
            width={Number(style?.width)}
            animate={{
              // animationWhitelist: ['style', 'size', 'opacity', 'data'], // Ideally need to restrict data
              onLoad: { duration: loadAnimationDuration }
            }}
            labels={({ datum }) => getLabel(datum)}
            labelComponent={
              <VictoryLabel {...labelSettings} style={labelStyleH} />
            }
            style={{
              data: {
                fill: ({ datum }) => {
                  let newColor = datum.color;
                  if (additional.isAltColor) {
                    newColor = datum.altColor;
                  }
                  return newColor;
                }
              }
            }}
          >
            <VictoryBar
              data={graphDataset}
              cornerRadius={additional.cornerRadius}
              barWidth={additional.barWidth}
            />
          </VictoryStack>
          {labelContent &&
            labelContent.map((thisLabel, labelIndex) => {
              return (
                <VictoryLabel
                  key={'label-' + labelIndex.toString()}
                  text={thisLabel.text}
                  x={thisLabel.x}
                  y={thisLabel.y}
                  textAnchor={thisLabel.textAnchor}
                  style={thisLabel.style ? thisLabel.style : labelStyleTitle}
                />
              );
            })}
        </VictoryGroup>
      )}

      {/* barHorizontal: Used for D20 */}
      {type === 'barHorizontal' && (
        <VictoryGroup
          domain={domain}
          domainPadding={domainPadding}
          padding={padding}
          width={Number(style?.width)}
          height={Number(style?.height)}
          animate={{
            duration: animationDuration,
            easing: 'cubicInOut'
          }}
          containerComponent={
            <VictoryContainer
              responsive={false}
              width={Number(style?.width)}
              height={Number(style?.height)}
              style={additional.style}
            />
          }
        >
          <VictoryStack
            width={Number(style?.width)}
            animate={{
              // animationWhitelist: ['style', 'size', 'opacity', 'data'], // Ideally need to restrict data
              onLoad: { duration: loadAnimationDuration }
            }}
            labels={({ datum }) => getLabel(datum)}
            labelComponent={
              <VictoryLabel {...labelSettings} style={labelStyleH} />
            }
            style={{
              data: {
                fill: ({ datum }) => {
                  let newColor = datum.color;
                  if (additional.isAltColor) {
                    newColor = datum.altColor;
                  }
                  return newColor;
                }
              }
            }}
          >
            <VictoryBar
              horizontal
              data={graphDataset}
              cornerRadius={additional.cornerRadius}
              barWidth={additional.barWidth}
            />
          </VictoryStack>
          {labelContent &&
            labelContent.map((thisLabel, labelIndex) => {
              return (
                <VictoryLabel
                  key={'label-' + labelIndex.toString()}
                  text={thisLabel.text}
                  x={thisLabel.x}
                  y={thisLabel.y}
                  textAnchor={thisLabel.textAnchor}
                  style={thisLabel.style ? thisLabel.style : labelStyleTitle}
                />
              );
            })}
        </VictoryGroup>
      )}

      {/* barHorizontalSmall: small normal and flipped bars */}
      {type === 'barHorizontalSmall' && (
        <VictoryGroup
          domain={domain}
          domainPadding={domainPadding}
          padding={padding}
          width={Number(style?.width)}
          height={Number(style?.height)}
          animate={{
            duration: animationDuration,
            easing: 'cubicInOut'
          }}
          containerComponent={
            <VictoryContainer
              responsive={false}
              width={Number(style?.width)}
              height={Number(style?.height)}
              style={additional.style}
            />
          }
        >
          <VictoryStack
            width={Number(style?.width)}
            animate={{
              // animationWhitelist: ['style', 'size', 'opacity', 'data'], // Ideally need to restrict data
              onLoad: { duration: loadAnimationDuration }
            }}
            // labels={({ datum }) => [datum.responseCount]}
            labels={({ datum }) => getLabel2(datum)}
            labelComponent={
              <VictoryLabel {...labelSettings} style={labelStyleHorSmall} />
            }
            style={{
              data: {
                fill: ({ datum }) => {
                  let newColor = datum.color;
                  if (additional.isAltColor) {
                    newColor = datum.altColor;
                  }
                  return newColor;
                }
              }
            }}
          >
            <VictoryBar
              data={graphDataset}
              y={(data) => {
                let newY = data.y;
                if (additional.isFlipped) {
                  newY = -Math.abs(data.y / Number(style?.width));
                  newY = newY * 0.5;
                }
                return newY;
              }}
              horizontal
              cornerRadius={additional.cornerRadius}
              barWidth={additional.barWidth}
            />
          </VictoryStack>
          {labelContent &&
            labelContent.map((thisLabel, labelIndex) => {
              return (
                <VictoryLabel
                  key={'label-' + labelIndex.toString()}
                  text={thisLabel.text}
                  x={thisLabel.x}
                  y={thisLabel.y}
                  textAnchor={thisLabel.textAnchor}
                  style={thisLabel.style ? thisLabel.style : labelStyleTitle}
                />
              );
            })}
        </VictoryGroup>
      )}

      {/* barStandard: Used for public and private cloud sidebar */}
      {type === 'barStandard' && (
        <VictoryGroup
          domain={domain}
          domainPadding={domainPadding}
          padding={padding}
          width={Number(style?.width)}
          height={Number(style?.height)}
          animate={{
            duration: animationDuration,
            easing: 'cubicInOut'
          }}
          containerComponent={
            <VictoryContainer
              responsive={false}
              width={Number(style?.width)}
              height={Number(style?.height)}
              style={additional.style}
            />
          }
        >
          <VictoryStack
            animate={{
              // animationWhitelist: ['style', 'size', 'opacity', 'data'], // Ideally need to restrict data
              onLoad: { duration: loadAnimationDuration }
            }}
            labels={({ datum }) => getLabelStringWithData(datum)}
            labelComponent={
              <VictoryLabel
                {...labelSettings}
                dx={({ datum }) => {
                  let newDx = -additional.barWidth / 2.25;
                  if (additional.isWeighted) {
                    newDx = -((datum.y / 100) * additional.barWidth) / 2.25;
                  }
                  if (additional.isAlt) {
                    newDx = -(datum.altWidth / 2.25);
                  }
                  return newDx;
                }}
                style={
                  additional.isWeighted || additional.isAlt
                    ? labelStyleWeighted
                    : labelStyleStandard
                }
              />
            }
            style={{
              data: {
                fill: ({ datum }) => {
                  let newColor = datum.color;
                  if (additional.isAltColor) {
                    newColor = datum.altColor;
                  }
                  return newColor;
                }
              }
            }}
          >
            <VictoryBar
              data={graphDataset}
              barWidth={({ datum }) => {
                let newBarWidth = additional.barWidth;
                if (additional.isWeighted) {
                  newBarWidth = (datum.y / 100) * additional.barWidth;
                }
                if (additional.isAlt) {
                  newBarWidth = datum.altWidth;
                }
                return newBarWidth;
              }}
              cornerRadius={additional.cornerRadius}
            />
          </VictoryStack>
          {labelContent &&
            labelContent.map((thisLabel, labelIndex) => {
              return (
                <VictoryLabel
                  key={'label-' + labelIndex.toString()}
                  text={thisLabel.text}
                  x={thisLabel.x}
                  y={thisLabel.y}
                  textAnchor={thisLabel.textAnchor}
                  style={thisLabel.style ? thisLabel.style : labelStyleTitle}
                />
              );
            })}
        </VictoryGroup>
      )}

      {/* barEmbeddedStandard: Used for data-5 */}
      {type === 'barEmbeddedStandard' && (
        <VictoryGroup
          domain={domain}
          domainPadding={domainPadding}
          padding={padding}
          width={Number(style?.width)}
          height={Number(style?.height)}
          animate={{
            duration: animationDuration,
            easing: 'cubicInOut'
          }}
          containerComponent={
            <VictoryContainer
              responsive={false}
              width={Number(style?.width)}
              height={Number(style?.height)}
              style={additional.style}
            />
          }
        >
          <VictoryStack
            animate={{
              // animationWhitelist: ['style', 'size', 'opacity', 'data'], // Ideally need to restrict data
              onLoad: { duration: loadAnimationDuration }
            }}
            labels={({ datum }) => getLabelStringWithData(datum)}
            labelComponent={
              <VictoryLabel
                {...labelSettings}
                dx={({ datum }) => {
                  let newDx = -additional.barWidth / 2.5;
                  if (additional.isWeighted) {
                    newDx = -((datum.y / 100) * additional.barWidth) / 2.25;
                  }
                  if (additional.isAlt) {
                    newDx = -(datum.altWidth / 2.25);
                  }
                  return newDx;
                }}
                style={
                  additional.isWeighted || additional.isAlt
                    ? labelStyleWeighted
                    : labelStyleStandard
                }
              />
            }
            style={{
              data: {
                fill: ({ datum }) => {
                  let newColor = datum.color;
                  if (additional.isAltColor) {
                    newColor = datum.altColor;
                  }
                  return newColor;
                }
              }
            }}
          >
            <VictoryBar
              data={graphDataset}
              barWidth={({ datum }) => {
                let newBarWidth = additional.barWidth;
                if (additional.isWeighted) {
                  newBarWidth = (datum.y / 100) * additional.barWidth;
                }
                if (additional.isAlt) {
                  newBarWidth = datum.altWidth;
                }
                return newBarWidth;
              }}
              cornerRadius={additional.cornerRadius}
            />
          </VictoryStack>
          {labelContent &&
            labelContent.map((thisLabel, labelIndex) => {
              return (
                <VictoryLabel
                  key={'label-' + labelIndex.toString()}
                  text={thisLabel.text}
                  x={thisLabel.x}
                  y={thisLabel.y}
                  textAnchor={thisLabel.textAnchor}
                  style={thisLabel.style ? thisLabel.style : labelStyleTitle}
                />
              );
            })}
        </VictoryGroup>
      )}

      {/* barCost: Used for cost */}
      {type === 'barCost' && (
        <VictoryGroup
          domain={domain}
          domainPadding={domainPadding}
          padding={padding}
          width={Number(style?.width)}
          height={Number(style?.height)}
          animate={{
            duration: animationDuration,
            easing: 'cubicInOut'
          }}
          containerComponent={
            <VictoryContainer
              responsive={false}
              width={Number(style?.width)}
              height={Number(style?.height)}
              style={additional.style}
            />
          }
        >
          <VictoryStack
            animate={{
              // animationWhitelist: ['style', 'size', 'opacity', 'data'], // Ideally need to restrict data
              onLoad: { duration: loadAnimationDuration }
            }}
            labels={({ datum }) => getLabelCost(datum)}
            labelComponent={
              <VictoryLabel
                {...labelSettings}
                dy={({ datum }) => {
                  let newDy = 0;
                  if (additional.isAlt) {
                    newDy = 0;
                  }
                  return newDy;
                }}
                style={
                  additional.isAlt ? labelStyleCostSidebar : labelStyleCost
                }
              />
            }
          >
            <VictoryBar
              data={graphDataset}
              barWidth={({ datum }) => {
                let newBarWidth = additional.barWidth;
                if (additional.isWeighted) {
                  newBarWidth = (datum.y / 100) * additional.barWidth;
                }
                // if (additional.isAlt) {
                //   newBarWidth = datum.altWidth;
                // }
                if (datum.barWidth) {
                  newBarWidth = datum.barWidth;
                }
                return newBarWidth;
              }}
              cornerRadius={{
                top: ({ datum }) => datum.cornerRadiusTop,
                bottom: ({ datum }) => datum.cornerRadiusBottom
              }}
              style={{
                data: {
                  stroke: ({ datum }) => datum.strokeColor,
                  strokeWidth: ({ datum }) => datum.strokeWidth,
                  fill: ({ datum }) => {
                    let newColor = datum.color;
                    // if (additional.isAltColor) {
                    //   newColor = datum.altColor;
                    // }
                    return newColor;
                  }
                }
              }}
            />
          </VictoryStack>
          {labelContent &&
            labelContent.map((thisLabel, labelIndex) => {
              return (
                <VictoryLabel
                  key={'label-' + labelIndex.toString()}
                  text={thisLabel.text}
                  x={thisLabel.x}
                  y={thisLabel.y}
                  textAnchor={thisLabel.textAnchor}
                  style={thisLabel.style ? thisLabel.style : labelStyleTitle}
                />
              );
            })}
        </VictoryGroup>
      )}

      {/* barCostSeparate: Used in cost for separate stacks */}
      {type === 'barCostSeparate' && (
        <>
          <VictoryGroup
            domain={domain}
            domainPadding={domainPadding}
            padding={padding}
            width={Number(style?.width)}
            height={Number(style?.height)}
            animate={{
              duration: animationDuration,
              easing: 'cubicInOut'
            }}
            containerComponent={
              <VictoryContainer
                responsive={false}
                width={Number(style?.width)}
                height={Number(style?.height)}
                style={additional.style}
              />
            }
          >
            <VictoryStack
              // horizontal
              animate={{
                // animationWhitelist: ['style', 'size', 'opacity', 'data'], // Ideally need to restrict data
                onLoad: { duration: loadAnimationDuration }
              }}
            >
              {graphDataset &&
                graphDataset.length > 0 &&
                graphDataset.map((thisData: any, i: number) => {
                  let thisIsAltColor = additional.isAltColor;
                  let thisLabelSettings = labelSettings;
                  let thisBarWidth = additional.barWidth;
                  // console.log('thisData', thisData);
                  return (
                    <VictoryBar
                      data={thisData}
                      labels={({ datum }) => getLabelCost(datum)}
                      // labels={({ datum }) => getLabelStringWithData(datum)}
                      // labels={['xxx']}
                      key={'stack-' + i}
                      style={{
                        data: {
                          fill: ({ datum }) => {
                            let newColor = datum.color;
                            if (thisIsAltColor) {
                              newColor = datum.altColor;
                            }
                            // if (additional.isAltColor) {
                            //   newColor = datum.altColor;
                            // }
                            return newColor;
                          }
                        }
                      }}
                      labelComponent={
                        <VictoryLabel
                          dx={({ datum }) => {
                            // TODO: Fix math here
                            // const newDx = (datum._y1 / 100) * -1; // Amazing mathz!, wrong mathz!
                            // const newDx = -Math.abs(datum.y * 4); // Good for larger, 800 (plus 30, 30 padding)
                            //const newDx = -Math.abs(datum.y * 1.7); // Good for smaller, 400 (plus 30, 30 padding)
                            // let newDx2 = -Math.abs(
                            //   Number(datum.y) * Number(style?.width) * 0.007
                            // ); // Still unsure on this formula
                            let newDx2 = -75;
                            return newDx2;
                          }}
                          // dy={({ datum }) => Number(datum.dy)}
                          style={[
                            {
                              ...TYPEFACE.bodyL,
                              fill: ({ datum }) => datum.labelColor
                            },
                            {
                              ...TYPEFACE.bodyL,
                              fill: ({ datum }) => datum.labelColor
                            },
                            {
                              ...TYPEFACE.bodyL,
                              fill: ({ datum }) => datum.labelColor
                            },
                            {
                              ...TYPEFACE.header,
                              fill: ({ datum }) => datum.labelColor,
                              fontSize: ({ datum }) => datum.numberFontSize
                            }
                          ]}
                          {...thisLabelSettings}
                          // TODO: add isAlt style toggle
                        />
                      }
                      barWidth={thisBarWidth}
                      cornerRadius={{
                        bottomLeft: ({ datum }) =>
                          datum.cornerRadiusBottomLeft
                            ? Number(datum.cornerRadiusBottomLeft)
                            : 0,
                        bottomRight: ({ datum }) =>
                          datum.cornerRadiusBottomRight
                            ? Number(datum.cornerRadiusBottomRight)
                            : 0,
                        topRight: ({ datum }) =>
                          datum.cornerRadiusTopRight
                            ? Number(datum.cornerRadiusTopRight)
                            : 0,
                        topLeft: ({ datum }) =>
                          datum.cornerRadiusTopLeft
                            ? Number(datum.cornerRadiusTopLeft)
                            : 0
                      }}
                    />
                  );
                })}
            </VictoryStack>
            {labelContent &&
              labelContent.map((thisLabel, labelIndex) => {
                return (
                  <VictoryLabel
                    key={'label-' + labelIndex.toString()}
                    text={thisLabel.text}
                    x={thisLabel.x}
                    y={thisLabel.y}
                    textAnchor={thisLabel.textAnchor}
                    style={thisLabel.style ? thisLabel.style : labelStyleTitle}
                  />
                );
              })}
          </VictoryGroup>
        </>
      )}

      {/* barRealities: Used in realities */}
      {type === 'barRealities' && (
        <>
          <VictoryGroup
            domain={domain}
            domainPadding={domainPadding}
            padding={padding}
            width={Number(style?.width)}
            height={Number(style?.height)}
            animate={{
              duration: animationDuration,
              easing: 'cubicInOut'
            }}
            containerComponent={
              <VictoryContainer
                responsive={false}
                width={Number(style?.width)}
                height={Number(style?.height)}
                style={additional.style}
              />
            }
          >
            <VictoryStack
              horizontal
              animate={{
                // animationWhitelist: ['style', 'size', 'opacity', 'data'], // Ideally need to restrict data
                onLoad: { duration: loadAnimationDuration }
              }}
            >
              {graphDataset &&
                graphDataset.map((thisData: any, i: number) => {
                  // Check if showOnly is noted
                  if (additional.showOnly && additional.showOnly.length > 0) {
                    const showOnly = additional.showOnly;
                    // If showOnly does not include this, do not show it
                    if (!showOnly.includes(thisData[0].x)) {
                      return (
                        <VictoryBar
                          key={'stack-' + i}
                          data={thisData}
                          style={{ data: { display: 'none' } }}
                        />
                      );
                    }
                  }

                  let thisIsAltColor = additional.isAltColor;
                  let thisLabelSettings = labelSettings;
                  let thisBarWidth = additional.barWidth;

                  let thisLabelStyle = labelStyleStacked;

                  if (additional.isAlt === true) {
                    thisLabelStyle = labelStyleStackedHidden;
                  }

                  if (additional.custom && additional.custom.length > 0) {
                    // Iterate through custom
                    for (const customIndex in additional.custom) {
                      const thisCustom = additional.custom[customIndex];
                      if (thisCustom.id === thisData[0].x) {
                        if (thisCustom.barWidth) {
                          thisBarWidth = thisCustom.barWidth;
                        }
                        if (thisCustom.labelSettings) {
                          thisLabelSettings = thisCustom.labelSettings;
                        }
                        if (thisCustom.isAltColor) {
                          thisIsAltColor = thisCustom.isAltColor;
                        }
                        if (thisCustom.labelStyle) {
                          thisLabelStyle = thisCustom.labelStyle;
                        }
                      }
                    }
                  }

                  return (
                    <VictoryBar
                      data={thisData}
                      labels={({ datum }) => getLabelStack(datum)}
                      key={'stack-' + i}
                      style={{
                        data: {
                          fill: ({ datum }) => {
                            let newColor = datum.color;
                            if (thisIsAltColor) {
                              newColor = datum.altColor;
                            }
                            // if (additional.isAltColor) {
                            //   newColor = datum.altColor;
                            // }
                            return newColor;
                          }
                        }
                      }}
                      padding={{ top: 150 }}
                      labelComponent={
                        <VictoryLabel
                          dx={({ datum }) => {
                            // TODO: Fix math here
                            // const newDx = (datum._y1 / 100) * -1; // Amazing mathz!, wrong mathz!
                            // const newDx = -Math.abs(datum.y * 4); // Good for larger, 800 (plus 30, 30 padding)
                            //const newDx = -Math.abs(datum.y * 1.7); // Good for smaller, 400 (plus 30, 30 padding)
                            const newDx2 = -Math.abs(
                              datum.y * Number(style?.width) * 0.0045
                            ); // Still unsure on this formula
                            // TODO: Need to account for padding.left, padding.right
                            // console.log(
                            //   datum.x,
                            //   '/// width, daytum.y, newDx = newDx2',
                            //   Number(style?.width),
                            //   datum.y,
                            //   newDx,
                            //   newDx2
                            // );
                            return newDx2;
                          }}
                          style={thisLabelStyle}
                          {...thisLabelSettings}
                          // TODO: add isAlt style toggle
                        />
                      }
                      barWidth={({ datum }) => {
                        return thisBarWidth;
                      }}
                      cornerRadius={additional.cornerRadius}
                    />
                  );
                })}
            </VictoryStack>
            {labelContent &&
              labelContent.map((thisLabel, labelIndex) => {
                return (
                  <VictoryLabel
                    key={'label-' + labelIndex.toString()}
                    text={thisLabel.text}
                    x={thisLabel.x}
                    y={thisLabel.y}
                    textAnchor={thisLabel.textAnchor}
                    style={thisLabel.style ? thisLabel.style : labelStyleTitle}
                  />
                );
              })}
          </VictoryGroup>
        </>
      )}

      {/* barEmbeddedList: Used for public and private cloud sidebar */}
      {type === 'barEmbeddedList' && (
        <VictoryGroup
          domain={domain}
          domainPadding={domainPadding}
          padding={padding}
          width={Number(style?.width)}
          height={Number(style?.height)}
          animate={{
            duration: animationDuration,
            easing: 'cubicInOut'
          }}
          containerComponent={
            <VictoryContainer
              responsive={false}
              width={Number(style?.width)}
              height={Number(style?.height)}
              style={additional.style}
            />
          }
        >
          <VictoryStack
            animate={{
              // animationWhitelist: ['style', 'size', 'opacity', 'data'], // Ideally need to restrict data
              onLoad: { duration: loadAnimationDuration }
            }}
            // labels={({ datum }) => [datum.x]}
            labels={({ datum }) => getLabelString(datum)}
            labelComponent={
              <VictoryLabel style={labelStyleList} {...labelSettings} x={0} />
            }
            style={{
              data: {
                fill: ({ datum }) => {
                  let newColor = datum.color;
                  if (additional.isAltColor) {
                    newColor = datum.altColor;
                  }
                  return newColor;
                }
              }
            }}
          >
            <VictoryBar
              horizontal
              data={graphDataset}
              sortKey={additional.sortKey}
              barWidth={({ datum }) => {
                let newBarWidth = additional.barWidth;
                if (additional.isWeighted) {
                  newBarWidth = (datum.y / 100) * additional.barWidth;
                }
                if (additional.isAlt) {
                  newBarWidth = datum.altWidth;
                }
                return newBarWidth;
              }}
              cornerRadius={additional.cornerRadius}
            />
          </VictoryStack>
          {labelContent &&
            labelContent.map((thisLabel, labelIndex) => {
              return (
                <VictoryLabel
                  key={'label-' + labelIndex.toString()}
                  text={thisLabel.text}
                  x={thisLabel.x}
                  y={thisLabel.y}
                  textAnchor={thisLabel.textAnchor}
                  style={thisLabel.style ? thisLabel.style : labelStyleTitle}
                />
              );
            })}
        </VictoryGroup>
      )}

      {/* barEmbeddedDetailList: Used for list with percentage */}
      {type === 'barEmbeddedDetailList' && (
        <VictoryGroup
          domain={domain}
          domainPadding={domainPadding}
          padding={padding}
          width={Number(style?.width)}
          height={Number(style?.height)}
          animate={{
            duration: animationDuration,
            easing: 'cubicInOut'
          }}
          containerComponent={
            <VictoryContainer
              responsive={false}
              width={Number(style?.width)}
              height={Number(style?.height)}
              style={additional.style}
            />
          }
        >
          <VictoryStack
            animate={{
              // animationWhitelist: ['style', 'size', 'opacity', 'data'], // Ideally need to restrict data
              onLoad: { duration: loadAnimationDuration }
            }}
            labels={({ datum }) => getLabelTip(datum)}
            labelComponent={
              <GraphEmbeddedListLabel {...labelSettings} typeface={TYPEFACE} />
            }
            style={{
              data: {
                fill: ({ datum }) => {
                  let newColor = datum.color;
                  if (additional.isAltColor) {
                    newColor = datum.altColor;
                  }
                  return newColor;
                }
              }
            }}
          >
            <VictoryBar
              horizontal
              data={graphDataset}
              sortKey={additional.sortKey}
              barWidth={({ datum }) => {
                let newBarWidth = additional.barWidth;
                if (additional.isWeighted) {
                  newBarWidth = (datum.y / 100) * additional.barWidth;
                }
                if (additional.isAlt) {
                  newBarWidth = datum.altWidth;
                }
                return newBarWidth;
              }}
              cornerRadius={additional.cornerRadius}
            />
          </VictoryStack>
          {labelContent &&
            labelContent.map((thisLabel, labelIndex) => {
              return (
                <VictoryLabel
                  key={'label-' + labelIndex.toString()}
                  text={thisLabel.text}
                  x={thisLabel.x}
                  y={thisLabel.y}
                  textAnchor={thisLabel.textAnchor}
                  style={thisLabel.style ? thisLabel.style : labelStyleTitle}
                />
              );
            })}
        </VictoryGroup>
      )}

      {/* contentList */}
      {type === 'contentList' && (
        <VictoryGroup
          domain={domain}
          domainPadding={domainPadding}
          padding={padding}
          width={Number(style?.width)}
          height={Number(style?.height)}
          animate={{
            duration: animationDuration,
            easing: 'cubicInOut'
          }}
          containerComponent={
            <VictoryContainer
              responsive={false}
              width={Number(style?.width)}
              height={Number(style?.height)}
              style={additional.style}
            />
          }
        >
          <VictoryStack
            animate={{
              // animationWhitelist: ['style', 'size', 'opacity', 'data'], // Ideally need to restrict data
              onLoad: { duration: loadAnimationDuration }
            }}
            // labels={({ datum }) => [datum.x]}
            labels={({ datum }) => getLabelString(datum)}
            labelComponent={
              <VictoryLabel style={labelStyleList} {...labelSettings} x={0} />
            }
            style={{
              data: {
                fill: ({ datum }) => {
                  let newColor = datum.color;
                  if (additional.isAltColor) {
                    newColor = datum.altColor;
                  }
                  return newColor;
                }
              }
            }}
          >
            <VictoryBar
              horizontal
              data={graphDataset}
              sortKey={additional.sortKey}
              barWidth={({ datum }) => {
                let newBarWidth = additional.barWidth;
                if (additional.isWeighted) {
                  newBarWidth = (datum.y / 100) * additional.barWidth;
                }
                if (additional.isAlt) {
                  newBarWidth = datum.altWidth;
                }
                return newBarWidth;
              }}
              cornerRadius={additional.cornerRadius}
            />
          </VictoryStack>
          {labelContent &&
            labelContent.map((thisLabel, labelIndex) => {
              return (
                <VictoryLabel
                  key={'label-' + labelIndex.toString()}
                  text={thisLabel.text}
                  x={thisLabel.x}
                  y={thisLabel.y}
                  textAnchor={thisLabel.textAnchor}
                  style={thisLabel.style ? thisLabel.style : labelStyleTitle}
                />
              );
            })}
        </VictoryGroup>
      )}

      {/* locationStack: Used in workload, need to manipulate the graphDataset */}
      {type === 'locationStack' && (
        <>
          <VictoryGroup
            domain={domain}
            domainPadding={domainPadding}
            padding={padding}
            width={Number(style?.width)}
            height={Number(style?.height)}
            animate={{
              duration: animationDuration,
              easing: 'cubicInOut'
            }}
            containerComponent={
              <VictoryContainer
                responsive={false}
                width={Number(style?.width)}
                height={Number(style?.height)}
                style={additional.style}
              />
            }
          >
            <VictoryStack
              horizontal
              animate={{
                // animationWhitelist: ['style', 'size', 'opacity', 'data'], // Ideally need to restrict data
                onLoad: { duration: loadAnimationDuration }
              }}
            >
              {graphDataset &&
                graphDataset.map((thisData: any, i: number) => {
                  return (
                    <VictoryBar
                      data={thisData}
                      labels={({ datum }) => {
                        if (!additional.hideLabel) {
                          if (datum.y === 0) {
                            return '';
                          } else {
                            return [Math.round(datum.y) + '%'];
                          }
                        } else {
                          return '';
                        }
                      }}
                      //labels={({ datum }) => getLabelStack(datum)}
                      key={'stack-' + i}
                      labelComponent={
                        <VictoryLabel
                          {...labelSettings}
                          dx={({ datum }) => {
                            // TODO: Fix math here
                            let thisDxModifier = 0.004;
                            if (additional.dxModifier) {
                              thisDxModifier = additional.dxModifier;
                            }
                            const newDx2 = -Math.abs(
                              datum.y * Number(style?.width) * thisDxModifier
                              // datum.y * Number(style?.width) * 0.0032
                            ); // Still unsure on this formula
                            // TODO: Need to account for padding.left, padding.right
                            return newDx2;
                          }}
                          style={
                            additional.isWeighted || additional.isAlt
                              ? labelStyleWeighted
                              : labelStyleStackLocation
                          }
                        />
                      }
                      barWidth={({ datum }) => {
                        let newBarWidth = additional.barWidth;
                        if (datum.barWidth) {
                          // console.log(' if barWidth', datum.barWidth);
                          newBarWidth = Number(datum.barWidth);
                        }
                        // let newBarWidth = additional.barWidth;
                        // if (additional.isWeighted) {
                        //   newBarWidth = (datum.y / 100) * additional.barWidth;
                        // }
                        if (additional.isAlt) {
                          newBarWidth = datum.altWidth;
                        }
                        return newBarWidth;
                      }}
                      cornerRadius={additional.cornerRadius}
                      style={{
                        data: {
                          fill: ({ datum }) => {
                            let newColor = datum.color;
                            if (additional.isAltColor) {
                              newColor = datum.altColor;
                            }
                            return newColor;
                          }
                        }
                      }}
                    />
                  );
                })}
            </VictoryStack>
            {titleDataset &&
              !additional.hideTitle &&
              titleDataset.map((thisData: any, i: number) => {
                // console.log('thisData', thisData);
                return (
                  <VictoryLabel
                    key={'label-' + i.toString()}
                    text={thisData}
                    x={5}
                    y={i * 69 + 65}
                    textAnchor="start"
                    verticalAnchor="middle"
                    style={labelStyleWorkload}
                  />
                );
              })}
            {labelContent &&
              labelContent.map((thisLabel, labelIndex) => {
                return (
                  <VictoryLabel
                    key={'label-' + labelIndex.toString()}
                    text={thisLabel.text}
                    x={thisLabel.x}
                    y={thisLabel.y}
                    textAnchor={thisLabel.textAnchor}
                    style={thisLabel.style ? thisLabel.style : labelStyleTitle}
                  />
                );
              })}
          </VictoryGroup>
        </>
      )}
    </div>
  );
};

// One way to hide Axis
// <VictoryAxis
// style={{
//   axis: { stroke: 'transparent' },
//   ticks: { stroke: 'transparent' },
//   tickLabels: { fill: 'transparent' }
// }}
// />

export default Graph;
