import { useHistory } from 'react-router-dom';
import { JSON_TEXT } from '../../constants';
import { useLang } from '../../contexts/LangContext';

type SectionNavProps = {
  currentSection?: string;
};

const SectionNav = ({ currentSection }: SectionNavProps) => {
  const history = useHistory();
  const langContext = useLang();

  if (!langContext) {
    return null;
  }

  const orderList = JSON_TEXT[langContext.id].pageOrder;

  return (
    <nav className="section-nav">
      <ul>
        {orderList.map((id: string) => {
          let isCurrent = false;
          if (currentSection === id) {
            isCurrent = true;
          }
          return (
            <li
              id={`nav-` + id}
              key={`nav-` + id}
              className={isCurrent ? 'current' : ''}
              onClick={() =>
                history.push('/view/' + id + history.location.search)
              }
            >
              {JSON_TEXT[langContext.id].chartLabels[`nav_${id}`]}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
export default SectionNav;
