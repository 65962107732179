import { ReactNode, createContext, useReducer, useContext } from 'react';
import { JSON_DATA } from '../constants';

type Action =
  | { type: 'CHANGE_INDUSTRY'; payload: industryType }
  | { type: 'DEFAULT'; payload: industryType };
type State = {
  id: string;
  label: string;
};
type IndustryProviderProps = {
  children: ReactNode;
};
type industryContextType = {
  id: string;
  label: string;
  setIndustry: (industry: industryType) => void;
};
type industryType = {
  id: string;
  label: string;
};

const industryReducer = (state: State, action: Action) => {
  //console.log('industryReducer action', action);
  switch (action.type) {
    case 'CHANGE_INDUSTRY': {
      localStorage.setItem('industry', JSON.stringify(action.payload));
      return {
        id: action.payload.id,
        label: action.payload.label
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

const IndustryContext = createContext<industryContextType | undefined>(
  undefined
);

const IndustryProvider = ({ children }: IndustryProviderProps) => {
  const localState = JSON.parse(localStorage.getItem('industry') || '{}');

  // Pull initiate state from dataset
  const initialState = JSON_DATA.filters.industry.default;

  const [state, dispatch] = useReducer(
    industryReducer,
    localState || initialState
  );

  // Check for empty local
  if (Object.keys(localState).length === 0) {
    console.log(
      '[IndustryContext] localState industry is empty, set initialState'
    );
    dispatch({ type: 'CHANGE_INDUSTRY', payload: initialState });
  }

  // Check if localState actually has valid values
  if (Object.keys(localState).length > 0) {
    // Pull values from dataset
    const validValues = JSON_DATA.filters.industry.options;
    const result = validValues.find(({ id }: any) => id === localState.id);
    if (!result) {
      dispatch({ type: 'CHANGE_INDUSTRY', payload: initialState });
    }
  }

  const setIndustry = (newIndustry: industryType) => {
    dispatch({ type: 'CHANGE_INDUSTRY', payload: newIndustry });
  };

  return (
    <IndustryContext.Provider
      value={{
        id: state.id,
        label: state.label,
        setIndustry
      }}
    >
      {children}
    </IndustryContext.Provider>
  );
};

const useIndustry = () => useContext(IndustryContext);

export { IndustryProvider, useIndustry };
