// Version of Section for print iteration
import { SectionDetailDataType } from '../../data/types';
import SectionDetail from './SectionDetail';
import { JSON_TEXT } from '../../constants';
import SectionNav from '../../components/SectionNav';
import Visualization from '../../components/Visualization';
import FilterGroup from '../../components/FilterGroup';
import { useLang } from '../../contexts/LangContext';
import { useRegion } from '../../contexts/RegionContext';
import { useSize } from '../../contexts/SizeContext';
import { useIndustry } from '../../contexts/IndustryContext';

const SectionPrint = () => {
  // console.log('[SectionPrint] ==============================');

  // const [isPrint, setIsPrint] = useState<boolean>(false);
  const regionContext = useRegion();
  const sizeContext = useSize();
  const industryContext = useIndustry();

  const visualizationWidth: number = 1400;
  const visualizationHeight: number = 675;

  const langContext = useLang();

  // Do not load if langContext is undefined
  if (!langContext) {
    return null;
  }

  // Define pages
  const pageOrder = JSON_TEXT[langContext.id].pageOrder;
  const pages = JSON_TEXT[langContext.id].pages;

  // Toggles visibility of disclaimer
  const onDisclaimerHandler = (isVisible: boolean) => {};
  // Toggles visibility of size disclaimer
  const onDisclaimerSizeHandler = (isVisible: boolean) => {};
  // Handles download action
  const onDownloadHandler = () => {};

  let sectionClassName = 'section';
  let pageCount = 1;

  // Set disclaimers purposely
  let isDisclaimerVisible = false;
  let isDisclaimerSizeVisible = false;
  const isReady =
    regionContext?.id !== '' &&
    sizeContext?.id !== '' &&
    industryContext?.id !== '' &&
    langContext?.id !== '';
  if (isReady) {
    switch (regionContext?.id) {
      case 'region_india':
      case 'region_japan':
        isDisclaimerVisible = true;
        break;
      default:
        isDisclaimerVisible = false;
    }
    switch (sizeContext?.id) {
      case 'size_smb':
      case 'size_enterprise':
        isDisclaimerSizeVisible = true;
        break;
      default:
        isDisclaimerSizeVisible = false;
    }
  }

  return (
    <section className={sectionClassName}>
      {pageOrder &&
        pageOrder.map((sectionKey: any) => {
          const sectionData = pages[sectionKey];
          const thisSectionDetails = sectionData.sections;
          // Loop through each callouts aka sections
          return thisSectionDetails.map(
            (section: SectionDetailDataType, sectionIndex: number) => {
              let contentClassName = 'content';
              if (sectionData && sectionData.isInverse) {
                contentClassName = contentClassName + ' inverse';
              }
              if (section && section.ignoreForPrint === true) {
                return null;
              }

              return (
                <div className="print">
                  <div className={contentClassName}>
                    <header>
                      {sectionData.logo && <div className="primary-logo" />}
                      <div>
                        {sectionKey === 'home' ? (
                          <h2 className="default">{sectionData.subtitle}</h2>
                        ) : (
                          <h2>{sectionData.subtitle}</h2>
                        )}
                        <h1>{sectionData.title}</h1>
                      </div>
                      {sectionKey !== 'home' && (
                        <SectionNav currentSection={sectionKey} />
                      )}
                    </header>
                    <article className="main">
                      <div className="restricted-group">
                        <div className="filter">
                          <FilterGroup
                            hideFilter={section.hideFilter}
                            additionalFilter={section.filter}
                            filterOverride={section.filterOverride}
                          />
                        </div>
                        <SectionDetail
                          currentSectionKey={sectionKey}
                          section={section}
                          onDownload={onDownloadHandler}
                        />
                      </div>
                      <div className="chart">
                        <Visualization
                          id={section.id}
                          width={visualizationWidth}
                          height={visualizationHeight}
                          onDisclaimerToggle={onDisclaimerHandler}
                          onDisclaimerSizeToggle={onDisclaimerSizeHandler}
                        />
                      </div>
                    </article>
                    <footer>
                      <a
                        href="https://hpe.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="secondary-logo" />
                      </a>
                      <div className="footnote">
                        {sectionData.footer}
                        <span className="page-number">{pageCount++}</span>
                        <div className="disclaimer">
                          {isDisclaimerSizeVisible &&
                            section.disclaimerSize && (
                              <span>{section.disclaimerSize}</span>
                            )}
                          {isDisclaimerVisible && section.disclaimer && (
                            <span>{section.disclaimer}</span>
                          )}
                        </div>
                      </div>
                    </footer>
                  </div>
                  {section.hideBg === false && section.bg && (
                    <div
                      className="bg"
                      style={{ backgroundImage: `url(${section.bg})` }}
                    ></div>
                  )}
                </div>
              );
            }
          );
        })}
    </section>
  );
};

export default SectionPrint;
