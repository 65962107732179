import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SectionDetailDataType } from '../../data/types';

type SectionDetailNavProps = {
  currentSectionKey?: string;
  currentDetailKey?: number;
  sections?: SectionDetailDataType[];
};

const SectionDetailNav = ({
  currentSectionKey,
  currentDetailKey,
  sections
}: SectionDetailNavProps) => {
  // console.log(' [SectionDetailNav]');
  const history = useHistory();
  const [total, setTotal] = useState<number>(0);
  const [showNav, setShowNav] = useState<boolean>(true);
  const [hasNext, setHasNext] = useState<boolean>(false);
  const [hasPrev, setHasPrev] = useState<boolean>(false);
  useEffect(() => {
    // console.log('[SectionDetailNav] sections', sections, currentDetailKey);
    if (sections) {
      setTotal(Object.keys(sections).length); // Set total
      if (currentDetailKey === 1) {
        // Determine if single page
        if (Object.keys(sections).length === 1) {
          // console.log('[SectionDetailNav] single page');
          setHasPrev(false);
          setHasNext(false);
          setShowNav(false);
        } else {
          // Is first page of many
          // console.log('[SectionDetailNav] first page of many');
          setHasPrev(false);
          setHasNext(true);
          setShowNav(true);
        }
      } else if (currentDetailKey === Object.keys(sections).length) {
        // Is last page of many
        // console.log('[SectionDetailNav] last page of many');
        setHasPrev(true);
        setHasNext(false);
        setShowNav(true);
      } else {
        // Is in the middle of many pages
        // console.log('[SectionDetailNav] middle page of many');
        setHasPrev(true);
        setHasNext(true);
        setShowNav(true);
      }
    }
  }, [sections, currentDetailKey]);

  if (!currentSectionKey || !currentDetailKey || !sections) {
    return null;
  }

  const onNext = () => {
    if (currentDetailKey) {
      let nextKey = currentDetailKey + 1;
      setHasPrev(true);
      if (nextKey > total - 1) {
        setHasNext(false);
      }
      history.push(
        '/view/' + currentSectionKey + '/' + nextKey + history.location.search
      );
    }
  };
  const onPrev = () => {
    if (currentDetailKey) {
      let prevKey = currentDetailKey - 1;
      if (prevKey < total) {
        setHasPrev(true);
        setHasNext(true);
      }
      if (prevKey === 1) {
        setHasPrev(false);
        setHasNext(true);
      }
      history.push(
        '/view/' + currentSectionKey + '/' + prevKey + history.location.search
      );
    }
  };

  if (!showNav) {
    return null;
  }

  return (
    <nav className="section-detail-nav">
      {showNav && (
        <>
          {hasPrev ? (
            <strong className="previous" onClick={onPrev}>
              ⟨
            </strong>
          ) : (
            <strong className="previous normal" />
          )}
          <strong className="results">
            {currentDetailKey} / {total}
          </strong>
          {hasNext ? (
            <strong className="next" onClick={onNext}>
              ⟩
            </strong>
          ) : (
            <strong className="next normal" />
          )}
        </>
      )}
    </nav>
  );
};
export default SectionDetailNav;
